import React from 'react';
import { useTranslation } from 'react-i18next';

export default function contentMischenUndBefuellen() {
  const { t } = useTranslation('common');
  return ({
    playlist: [
      {
        id: 'elearning.video.mixingAndFillingOnTheFarm.id',
        title: 'elearning.video.mixingAndFillingOnTheFarm.text',
      },
      {
        id: 'elearning.video.containerDisposal.id',
        title: 'elearning.video.containerDisposal.text',
      },
    ],
    docs: [
      <a href={t('elearning.doc.befuellUndWaschplatzFuerSpritzgeraete.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.befuellUndWaschplatzFuerSpritzgeraete.text')}
      </a>,
      <a href={t('elearning.doc.interkantonaleEmpfehlungWaschplatz.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.interkantonaleEmpfehlungWaschplatz.text')}
      </a>,
      <a href={t('elearning.doc.fachgerechteBefuellungReinigungSG.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.fachgerechteBefuellungReinigungSG.text')}
      </a>,
      <a href={t('elearning.doc.ansprechpersonenWaschBefuellplatzKOLAS.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.ansprechpersonenWaschBefuellplatzKOLAS.text')}
      </a>,
    ],
    links: [
      <a href={t('elearning.link.agroscopeSpritzmittelrechner.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.link.agroscopeSpritzmittelrechner.text')}
      </a>,
    ],
  });
}
