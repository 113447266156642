/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

// Define a type for the slice state
interface moduleState {
  modules: any
}

// Define the initial state using that type
const initialState: moduleState = {
  modules: [],
};

export const moduleSlice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    modulesLoaded: (state, action) => {
      state.modules = action.payload;
    },
  },
});

export const {
  modulesLoaded,
} = moduleSlice.actions;

export default moduleSlice.reducer;
