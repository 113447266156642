import {
  configureStore, AnyAction, combineReducers,
} from '@reduxjs/toolkit';
import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, createStore } from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import assessmentSlice from './reducer/assessmentSlice';
import currentAssessmentSlice from './reducer/currentAssessmentSlice';
import moduleSlice from './reducer/moduleSlice';
import questionSlice from './reducer/questionSlice';

// const store = configureStore({
//   reducer: {
//     lessonState: assessmentSlice,
//     retakeState: retakeSlice,
//     moduleState: moduleSlice,
//     questionState: questionSlice,
//     currentAssessmentState: currentAssessmentSlice,
//   },
//   middleware: [thunk],
// });

const rootReducer = combineReducers({
  lessonState: assessmentSlice,
  moduleState: moduleSlice,
  questionState: questionSlice,
  currentAssessmentState: currentAssessmentSlice,
});

const composedEnhancer = composeWithDevTools(applyMiddleware(thunk));

const preloadedState = {};

const store = createStore(
  rootReducer,
  preloadedState,
  composedEnhancer,
);

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>
