import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import YTVideo from './YTVideo';

interface Props {
    pl: Array<Record<string, any>>,
}

export default function YTPlaylist({ pl }: Props) {
  const { t } = useTranslation('common');
  const [video, setVideo] = useState<Record<string, string>>(pl[0]);
  const clickHandler = (item: Record<string, any>) => {
    setVideo(item);
  };

  return (
    <Row>
      <Col sm={12} md={6}>
        <section className="section">
          <YTVideo
            id={t(video.id)}
            title={t(video.title)}
            className="sct-auto-resizable-iframe"
          />
        </section>
      </Col>
      <Col sm={12} md={6}>
        <section className="section">
          <div>
            <h4>{t('elearning.tab.title.videos')}</h4>
          </div>
          <ul className="sct-video-pl">
            {pl.map((item, idx) => (
              <li
                className={(item.id === t(video.id)) ? 'sct-video-pls-item active' : 'sct-video-pls-item'}
                key={t(item.title)}
              >
                <span
                  role="button"
                  tabIndex={idx}
                  onKeyPress={() => clickHandler(item)}
                  onClick={() => clickHandler(item)}
                >
                  <i className="bi bi-film" />
                  &nbsp;
                  {t(item.title)}
                </span>
              </li>
            ))}
          </ul>
        </section>
      </Col>
    </Row>
  );
}
