import React from 'react';
import {
  Col, Table,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../UserContext';
import UpdateProfile from './UpdateProfile';
import CustomModal from '../CustomModal';

export default function UserProfile() {
  const { t } = useTranslation('common');
  return (
    <UserContext.Consumer>
      {({ user, setUser }) => (
        <Col>
          <section className="section">
            <Table responsive striped className="assessment-overview-table">
              <caption className="table-caption-lg-top">{t('pages.profile.table.caption')}</caption>
              <tbody>
                <tr>
                  <td>{t('form.labels.firstName')}</td>
                  <td>{user?.name}</td>
                </tr>
                <tr>
                  <td>{t('form.labels.lastName')}</td>
                  <td>{user?.lastName}</td>
                </tr>
                <tr>
                  <td>{t('form.labels.email')}</td>
                  <td>{user?.email}</td>
                </tr>
                <tr>
                  <td>{t('form.labels.fieldOfActivity')}</td>
                  <td>{t([`form.labels.activityOptions.${user?.userGroup}`, `${user?.userGroup}`])}</td>
                </tr>
              </tbody>
            </Table>
          </section>
          <div className="button-wrapper">
            <CustomModal modalTitel={t('pages.profile.modal.title') ?? ''} buttonText={t('pages.profile.modal.button.change') ?? ''}>
              <UpdateProfile user={user} setUser={setUser} />
            </CustomModal>
          </div>
        </Col>
      )}
    </UserContext.Consumer>
  );
}
