/* eslint-disable react/no-array-index-key */
/**
 * TODO:
 * - Correct answers should not be visible in dev tools!! Remove them from state questions
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import FormWrapper from '../FormWrapper';

type Props = {
  module: Record<string, any>,
};

function AssessmentQuestions({ module }: Props) {
  const { t } = useTranslation('common');

  return (
    <Col>
      <section className="section">
        <h2>{t('assessment.title')}</h2>
      </section>
      <section className="section">
        <FormWrapper
          moduleId={module.id}
        />
      </section>
    </Col>
  );
}

export default AssessmentQuestions;
