import React from 'react';
import { useTranslation } from 'react-i18next';
import TitleBar from '../layout/PageTitle';
import AssessmentOverviewTable from './AssessementOverviewTable';

export default function AssessmentOverview() {
  const { t } = useTranslation('common');
  return (
    <>
      <TitleBar pageTitle={t('pages.assessmentOverview.title')} />
      <AssessmentOverviewTable />
    </>
  );
}
