import React from 'react';
import { useTranslation } from 'react-i18next';

const legalBasis = () => {
  const { t } = useTranslation('common');
  return ([
    <a target="_blank" rel="noreferrer" href={t('elearning.doc.handbuchGewaesserschutzkontrollen1.link') ?? ''}>
      {t('elearning.doc.handbuchGewaesserschutzkontrollen1.text')}
    </a>,
    <a target="_blank" rel="noreferrer" href={t('elearning.doc.handbuchGewaesserschutzkontrollen2.link') ?? ''}>
      {t('elearning.doc.handbuchGewaesserschutzkontrollen2.text')}
    </a>,
    <a target="_blank" rel="noreferrer" href={t('elearning.doc.handbuchGewaesserschutzkontrollen3.link') ?? ''}>
      {t('elearning.doc.handbuchGewaesserschutzkontrollen3.text')}
    </a>,
    <a target="_blank" rel="noreferrer" href={t('elearning.doc.kontrollhandbuchGewaesserschutzInDerLandwirtschaft.link') ?? ''}>
      {t('elearning.doc.kontrollhandbuchGewaesserschutzInDerLandwirtschaft.text')}
    </a>,
    <a target="_blank" rel="noreferrer" href={t('elearning.doc.landwirtschaftsgesetz.link') ?? ''}>
      {t('elearning.doc.landwirtschaftsgesetz.text')}
    </a>,
    <a target="_blank" rel="noreferrer" href={t('elearning.doc.direktzahlungsverordnung.link') ?? ''}>
      {t('elearning.doc.direktzahlungsverordnung.text')}
    </a>,
    <a target="_blank" rel="noreferrer" href={t('elearning.doc.gewaesserschutzgesetz.link') ?? ''}>
      {t('elearning.doc.gewaesserschutzgesetz.text')}
    </a>,
    <a target="_blank" rel="noreferrer" href={t('elearning.doc.gewaesserschutzverordnung.link') ?? ''}>
      {t('elearning.doc.gewaesserschutzverordnung.text')}
    </a>,
    <a target="_blank" rel="noreferrer" href={t('elearning.doc.chemikalienrisikoreduktionsverordnung.link') ?? ''}>
      {t('elearning.doc.chemikalienrisikoreduktionsverordnung.text')}
    </a>,
    <a target="_blank" rel="noreferrer" href={t('elearning.doc.pflanzenschutzmittelverzeichnis.link') ?? ''}>
      {t('elearning.doc.pflanzenschutzmittelverzeichnis.text')}
    </a>,
  ]);
};

export default legalBasis;
