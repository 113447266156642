import React, { useEffect } from 'react';
import {
  Form, FormGroup,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from './reducer/reduxHooks';
import validateInput from '../utils/validateInput';

interface InputFieldProps {
  questionId: string,
  question: string,
  order: number,
  moduleName: string,
  currentAssessmentState: Record<string, any>,
  options: Array<Record<string, any>>,
  subtitle?: string,
  setButtonActive: any,
}

export default function SelectInput({
  questionId,
  question,
  order,
  subtitle,
  moduleName,
  currentAssessmentState,
  options,
  setButtonActive,
}: InputFieldProps) {
  const { i18n, t } = useTranslation('common');
  const dispatch = useAppDispatch();

  useEffect(() => {
    setButtonActive(
      validateInput(currentAssessmentState.assessment[questionId].answers, 'select'),
    );
  }, [currentAssessmentState]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement | any>): void => {
    const [selectedAnswer] = options
      .filter((option) => option.id === e.currentTarget.options[e.currentTarget.selectedIndex].id);
    const payload = {
      id: questionId,
      order,
      customQuestionType: 'select',
      text: question,
      answers: {
        id: e.currentTarget.options[e.currentTarget.selectedIndex].id,
        text: selectedAnswer.text,
        text_fr: selectedAnswer.text_fr,
        text_it: selectedAnswer.text_it,
        checked: true,
      },
    };
    dispatch({ type: 'currentAssessment/updateSelect', payload, moduleName: moduleName.toLowerCase() });
  };

  function createSelectInput(option: string, id: string, key: number) {
    return (
      <option
        key={key}
        id={id}
        value={option}
      >
        {option}
      </option>
    );
  }

  function createAnswers(): Array<React.ReactNode> {
    return options.map((option, index) => {
      const text = (i18n.language !== 'de') ? option[`text_${i18n.language}`] ?? option.text : option.text;
      return createSelectInput(text, option.id, index + 1);
    });
  }

  return (
    <FormGroup controlId={questionId} className="mb-3">
      <h3>{question}</h3>
      <h4><em>{subtitle}</em></h4>
      <Form.Select
        onChange={(e) => handleCheckboxChange(e)}
      >
        <option>{t('form.labels.pleaseSelect')}</option>
        {createAnswers()}
      </Form.Select>
    </FormGroup>
  );
}

SelectInput.defaultProps = {
  subtitle: null,
};
