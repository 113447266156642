import React from 'react';
import { useTranslation } from 'react-i18next';

export default function contentAbschwemmung() {
  const { t } = useTranslation('common');
  return ({
    playlist: [
      {
        id: 'elearning.video.understandRunOff.id',
        title: 'elearning.video.understandRunOff.text',
      },
      {
        id: 'elearning.video.runOffMeasuresArableFarming.id',
        title: 'elearning.video.runOffMeasuresArableFarming.text',
      },
      {
        id: 'elearning.video.runOffMeasuresOrchards.id',
        title: 'elearning.video.runOffMeasuresOrchards.text',
      },
      {
        id: 'elearning.video.runOffMeasuresViticulture.id',
        title: 'elearning.video.runOffMeasuresViticulture.text',
      },
    ],
    docs: [
      <a href={t('elearning.doc.pufferstreifen.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.pufferstreifen.text')}
      </a>,
      <a href={t('elearning.doc.reduktionDriftAckerbau.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.reduktionDriftAckerbau.text')}
      </a>,
      <a href={t('elearning.doc.reduktionDriftObstbau.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.reduktionDriftObstbau.text')}
      </a>,
      <a href={t('elearning.doc.reduktionDriftWeinbau.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.reduktionDriftWeinbau.text')}
      </a>,
      <a href={t('elearning.doc.weisungRisikoreduktionBLW.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.weisungRisikoreduktionBLW.text')}
      </a>,
      <a href={t('elearning.doc.toppsBestPracticeRunOffErosion.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.toppsBestPracticeRunOffErosion.text')}
      </a>,
    ],
    links: [
      <a href={t('elearning.link.JKIOriginaltabellen.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.link.JKIOriginaltabellen.text')}
      </a>,
    ],
  });
}
