/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
interface currentAssessmentState {
  currentAssessment: {
    [key: string]: {
      [key: string]: {
        [key: string]: any
      }
    }
  }
}

// Define the initial state using that type
const initialState: currentAssessmentState = {
  currentAssessment: {},
};

function updateObjectInArray(array: Array<Record<string, any>>, object: Record<string, any>) {
  // update an object value in answer array
  // find index
  const index = array.findIndex((arrayObject: Record<string, any>) => arrayObject.id === object.id);
  // copy existing answer array
  const newAnswerArray = [...array];
  // replace object in array
  newAnswerArray[index] = object;
  // update array in state
  return newAnswerArray;
}

// function updateSingleChoice(array: Array<Record<string, any>>, object: Record<string, any>) {
//   // update an object value in answer array
//   const newAnswerArray: Array<Record<string, any>> = [];
//   // replace / modify object in array
//   array.forEach((arrayObject: Record<string, any>) => {
//     if (arrayObject.id !== object.id) {
//       arrayObject.checked = 'false';
//       newAnswerArray.push(arrayObject);
//     } else {
//       newAnswerArray.push(object);
//     }
//   });
//   // update array in state
//   return newAnswerArray;
// }

export const currentAssessmentSlice = createSlice({
  name: 'currentAssessment',
  initialState,
  reducers: {
    updateInput: (state, action: any) => {
      const { moduleName, payload } = action;
      if (!state.currentAssessment[moduleName]) {
        state.currentAssessment[moduleName] = {
          assessment: {
            [payload.id]: {
              text: payload.text,
              order: payload.order,
              customQuestionType: payload.customQuestionType,
              answers: [...state.currentAssessment[moduleName].assessment[payload.id].answers,
                action.payload.answers],
            },
          },
        };
      } else if (!state.currentAssessment[moduleName].assessment[payload.id]) {
        state.currentAssessment[moduleName].assessment[payload.id] = {
          text: payload.text,
          order: payload.order,
          customQuestionType: payload.customQuestionType,
          answers: [...state.currentAssessment[moduleName].assessment[payload.id].answers,
            action.payload.answers],
        };
      } else {
        const answerArray = state.currentAssessment[moduleName].assessment[payload.id].answers;
        const newAnswerArray = updateObjectInArray(answerArray, payload.answers);
        // update array in state
        // state.currentAssessment[moduleName].assessment[payload.id] = {
        //   text: payload.text,
        //   order: payload.order,
        //   customQuestionType: payload.customQuestionType,
        //   answers: newAnswerArray,
        // };
        state.currentAssessment[moduleName].assessment[payload.id].answers = newAnswerArray;
      }
    },
    updateSelect: (state, action: any) => {
      const { moduleName, payload } = action;
      if (!state.currentAssessment[moduleName]) {
        state.currentAssessment[moduleName] = {
          assessment: {
            [payload.id]: {
              text: payload.text,
              order: payload.order,
              customQuestionType: payload.customQuestionType,
              answers: [...state.currentAssessment[moduleName].assessment[payload.id].answers,
                action.payload.answers],
            },
          },
        };
      } else if (!state.currentAssessment[moduleName].assessment[payload.id]) {
        state.currentAssessment[moduleName].assessment[payload.id] = {
          text: payload.text,
          order: payload.order,
          customQuestionType: payload.customQuestionType,
          answers: [...state.currentAssessment[moduleName].assessment[payload.id].answers,
            action.payload.answers],
        };
      } else {
        // state.currentAssessment[moduleName].assessment[payload.id] = {
        //   text: payload.text,
        //   order: payload.order,
        //   customQuestionType: payload.customQuestionType,
        //   answers: [action.payload.answers],
        // };
        state.currentAssessment[moduleName].assessment[payload.id].answers = [action.payload.answers];
      }
    },
    updateCheckbox: (state, action: any) => {
      const { moduleName, payload } = action;
      if (!state.currentAssessment[moduleName]) {
        state.currentAssessment[moduleName] = {
          assessment: {
            [payload.id]: {
              text: payload.text,
              order: payload.order,
              customQuestionType: payload.customQuestionType,
              answers: [...state.currentAssessment[moduleName].assessment[payload.id].answers,
                action.payload.answers],
            },
          },
        };
      } else if (!state.currentAssessment[moduleName].assessment[payload.id]) {
        state.currentAssessment[moduleName].assessment[payload.id] = {
          text: payload.text,
          order: payload.order,
          customQuestionType: payload.customQuestionType,
          answers: [...state.currentAssessment[moduleName].assessment[payload.id].answers,
            action.payload.answers],
        };
      } else {
        const answerArray = state.currentAssessment[moduleName].assessment[payload.id].answers;
        const newAnswerArray = updateObjectInArray(answerArray, payload.answers);
        // update array in state
        state.currentAssessment[moduleName].assessment[payload.id].answers = newAnswerArray;
      }
    },
    updateRadio: (state, action: any) => {
      const { moduleName, payload } = action;
      if (!state.currentAssessment[moduleName]) {
        state.currentAssessment[moduleName] = {
          assessment: {
            [payload.id]: {
              text: payload.text,
              order: payload.order,
              customQuestionType: payload.customQuestionType,
              answers: [...state.currentAssessment[moduleName].assessment[payload.id].answers,
                action.payload.answers],
            },
          },
        };
      } else if (!state.currentAssessment[moduleName].assessment[payload.id]) {
        state.currentAssessment[moduleName].assessment[payload.id] = {
          text: payload.text,
          order: payload.order,
          customQuestionType: payload.customQuestionType,
          answers: [...state.currentAssessment[moduleName].assessment[payload.id].answers,
            action.payload.answers],
        };
      } else {
        // const answerArray = state.currentAssessment[moduleName].assessment[payload.id].answers;
        // const newAnswerArray = updateSingleChoice(answerArray, payload.answers);
        // // update array in state
        // state.currentAssessment[moduleName].assessment[payload.id] = {
        //   text: payload.text,
        //   customQuestionType: payload.customQuestionType,
        //   answers: newAnswerArray,
        // };

        // state.currentAssessment[moduleName].assessment[payload.id] = {
        //   text: payload.text,
        //   order: payload.order,
        //   customQuestionType: payload.customQuestionType,
        //   answers: [action.payload.answers],
        // };

        // only answers array will be updated
        state.currentAssessment[moduleName].assessment[payload.id].answers = [action.payload.answers];
      }
    },
    assessmentFormSubmitted: (state, action: any) => {
      const { moduleId, payload } = action;
      state.currentAssessment[moduleId].submitted = payload.submitted;
      state.currentAssessment[moduleId].uid = payload.uid;
      state.currentAssessment[moduleId].timestamp = payload.timestamp;
    },
    questionsPreloaded: (state, action: any) => {
      state.currentAssessment[action.payload.moduleId] = action.payload.payload;
    },
  },
});

export const {
  updateCheckbox,
  updateRadio,
} = currentAssessmentSlice.actions;

export default currentAssessmentSlice.reducer;
