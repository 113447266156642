/* eslint-disable class-methods-use-this */
import getJWT from '../auth/getJWT';

class HttpService {
  async fetch(
    method: string,
    url: string,
    data: Record<string, any> | undefined,
    headers?: Record<string, any>,
  ) {
    const fetchHeaders = new Headers({ 'content-type': 'application/json', ...(headers || {}) });
    const tokenKey = await getJWT();

    if (tokenKey) {
      fetchHeaders.append('Authorization', `Bearer ${tokenKey}`);
    }

    return fetch(url, {
      method,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: fetchHeaders,
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data),
    }).then(async (res) => {
      const body = await res.json();
      return (
        {
          statusCode: res.status,
          body,
        }
      );
    });
  }
}

export const httpService = new HttpService();
