import { v4 as uuidv4 } from 'uuid';
import validateUniqueId from './validateUniqueId';

function generateUniqueId(answerIds: Array<string>) {
  const uid = uuidv4();
  // validate uid
  if (validateUniqueId(uid, answerIds) === false) {
    // try again
    generateUniqueId(answerIds);
  }
  return uid;
}

export default generateUniqueId;
