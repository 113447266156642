import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LogoSection from './logos/LogoSection';

export default function Home() {
  const { t } = useTranslation('common');

  return (
    <Row>
      <Col>
        <section className="module-title-section">
          <h1>{t('pages.home.title')}</h1>
        </section>
        <div className="sct-tagline">
          {t('pages.home.tagline')}
        </div>
        <p>
          {t('pages.home.introText')}
        </p>
        <p>
          {t('pages.home.twoModuleSections')}
        </p>

        <section className="section">
          <Card className="sct-bs-card-row">
            <Card.Img className="sct-bs-card-img-top" variant="top" src="./assets/img/SCT-icon.png" />
            <Card.Body>
              <Card.Title>{t('pages.home.cards.module.title')}</Card.Title>
              <Card.Text>
                {t('pages.home.cards.module.text')}
                <ul>
                  <li>{t('pages.home.cards.module.list.item1')}</li>
                  <li>{t('pages.home.cards.module.list.item2')}</li>
                </ul>
              </Card.Text>
              <Card.Title>{t('pages.home.cards.elearning.title')}</Card.Title>
              <Card.Text>
                {t('pages.home.cards.elearning.text')}
              </Card.Text>
              <div className="button-wrapper">
                <Link to="/modules/">
                  <Button variant="primary">{t('pages.home.buttons.moduleOverview')}</Button>
                </Link>
              </div>
            </Card.Body>
          </Card>
        </section>
        <section className="section">
          <LogoSection
            title={t('pages.home.logoSection.title') ?? 'Ein Beratungsinstrument von'}
          />
        </section>
        <section className="section">
          <h2>
            {`${t('pages.home.financialSupport.title')}:`}
          </h2>
          <Row className="logo-wrapper">
            <Col md="4">
              <section className="section">
                <a href={t('pages.home.financialSupport.logo.bafu.link') ?? 'https://www.bafu.admin.ch/bafu/de/home.html'} target="_blank" rel="noreferrer">
                  <img src="./assets/img/BAFU_dfi_RGB_pos_quer.png" alt="BAFU Logo" className="logo" width="400" />
                </a>
              </section>
            </Col>
            <Col md="4">
              <section className="section">
                <a href={t('pages.home.financialSupport.logo.kvu.link') ?? 'https://www.kvu.ch'} target="_blank" rel="noreferrer">
                  <img src="./assets/img/logo-kvu-de.png" alt="KVU Logo" className="logo" />
                </a>
              </section>
            </Col>
            <Col md="4">
              <section className="section">
                <a href={t('pages.home.financialSupport.logo.kolas.link') ?? 'https://ldkcdca.cloudrexx.com/de/kolas-cosac/'} target="_blank" rel="noreferrer">
                  <img src="./assets/img/logo-kolas.png" alt="KOLAS Logo" className="logo" />
                </a>
              </section>
            </Col>
          </Row>
        </section>
      </Col>
    </Row>
  );
}
