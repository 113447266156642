import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion, Col, Row, Tab, Tabs,
} from 'react-bootstrap';
import useWindowDimensions from '../../utils/useWindowDimensions';
import elearningTabContent from './elearningTabContent';

interface Props {
  moduleContent: () => Record<string, any>,
}

export default function Elearning({ moduleContent }: Props) {
  const { t } = useTranslation('common');
  const content = elearningTabContent(moduleContent());
  const { windowInnerWidth } = useWindowDimensions();
  return (
    <section id="elearning" className="section">
      <Row>
        <h2>{t('elearning.title')}</h2>
      </Row>
      <Row>
        {(content)
          ? (
            <Col>
              {(content.tabs !== null)
                ? (
                  <section className="section">
                    {(windowInnerWidth >= 576)
                      ? (
                        <Tabs defaultActiveKey="videos" id="uncontrolled-tab-example" className="mb-3">
                          {content.tabs.tabList.map((tab: Record<string, any>) => (
                            <Tab eventKey={tab.eventKey} title={tab.tabTitle} key={tab.tabTitle}>
                              <Row>
                                <Col>
                                  {tab.tabContent}
                                </Col>
                              </Row>
                            </Tab>
                          ))}
                        </Tabs>
                      )
                      : (
                        <Accordion defaultActiveKey="videos">
                          {content.tabs.tabList.map((tab: Record<string, any>) => (
                            <Accordion.Item eventKey={tab.eventKey} key={tab.tabTitle}>
                              <Accordion.Header>{tab.tabTitle}</Accordion.Header>
                              <Accordion.Body>
                                <Row>
                                  <Col>
                                    {tab.tabContent}
                                  </Col>
                                </Row>
                              </Accordion.Body>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      )}
                  </section>
                )
                : null}
            </Col>
          )
          : null}
      </Row>
    </section>
  );
}
