import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';

interface Props {
    id: string,
    className: string
}

export default function Footer({ id, className }: Props) {
  const { t } = useTranslation('common');
  return (
    <footer id={id} className={className}>
      <Row className="footer-content">
        <Col md="auto">
          {`© ${new Date().getFullYear()} `}
          {t('pages.footer.rightsReserved')}
        </Col>
        <Col md="auto">
          <a href="/contact">{t('pages.footer.links.contact')}</a>
          &nbsp;|&nbsp;
          <a href="/impressum">{t('pages.footer.links.impressum')}</a>
          &nbsp;|&nbsp;
          <a href="/nutzungsbedingungen">{t('pages.footer.links.policy')}</a>
        </Col>
      </Row>
    </footer>
  );
}
