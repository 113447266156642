import React from 'react';
import { Col, Spinner } from 'react-bootstrap';

export default function IsLoading() {
  return (
    <Col>
      <div className="is-loading-spinner-wrapper">
        <Spinner className="is-loading-spinner" animation="border" variant="secondary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </Col>
  );
}
