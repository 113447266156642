import React from 'react';
import { Col, OverlayTrigger, Popover } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
  title: string,
  id: string,
  imgSrc: string,
  description: string | null,
  completed: string | boolean,
  key: string,
  status: string,
}

function ModuleThumbnail({
  title, id, imgSrc, description, completed, key, status,
}: Props) {
  return (
    <Col lg="4" id={id} key={key}>
      <OverlayTrigger
        trigger={['hover', 'focus']}
        key={`OverlayTrigger-${key}`}
        placement="auto"
        overlay={(
          <Popover id={`popover-positioned-${title.toLowerCase()}`}>
            <Popover.Header as="h3">
              <Trans i18nKey="popover.headerTitle">
                Modul&npsp;
                {{ title }}
              </Trans>
            </Popover.Header>
            <Popover.Body>
              {description}
            </Popover.Body>
          </Popover>
                  )}
      >
        <Link to={(status === 'published') ? `/modules/${id}` : '/modules/'}>
          <div className={(id !== 'anwenderschutz') ? 'module-thumbnail-wrapper' : 'module-thumbnail-wrapper module-in-progress'}>
            <span className="module-thumbnail-patch">{(completed) ? <i className="bi bi-patch-check-fill success highlighted" /> : <i className="bi bi-patch-check" />}</span>
            <div className="module-thumbnail-header">
              <h4>{title}</h4>
            </div>
            <div className="module-thumbnail-body">
              <img src={imgSrc} alt={`Illustration ${title}`} />
            </div>
          </div>
        </Link>
      </OverlayTrigger>
    </Col>
  );
}

export default ModuleThumbnail;
