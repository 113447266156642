import React from 'react';
import { useTranslation } from 'react-i18next';

export default function contentWaschplatz() {
  const { t } = useTranslation('common');
  return ({
    playlist: [
      {
        id: 'elearning.video.waschplatz.id',
        title: 'elearning.video.waschplatz.text',
      },
      {
        id: 'elearning.video.umgangMitZwischengelagertemReinigungswasser.id',
        title: 'elearning.video.umgangMitZwischengelagertemReinigungswasser.text',
      },
      {
        id: 'elearning.video.lagerungVonReinigungswasser.id',
        title: 'elearning.video.lagerungVonReinigungswasser.text',
      },
    ],
    docs: [
      <a href={t('elearning.doc.befuellUndWaschplatzFuerSpritzgeraete.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.befuellUndWaschplatzFuerSpritzgeraete.text')}
      </a>,
      <a href={t('elearning.doc.interkantonaleEmpfehlungWaschplatz.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.interkantonaleEmpfehlungWaschplatz.text')}
      </a>,
      <a href={t('elearning.doc.merkblattFuellUndWaschplaetzeBL.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.merkblattFuellUndWaschplaetzeBL.text')}
      </a>,
      <a href={t('elearning.doc.merkblattBefuellenSpuelenUndWaschenFR.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.merkblattBefuellenSpuelenUndWaschenFR.text')}
      </a>,
      <a href={t('elearning.doc.merkblattFuellUndWaschplaetzeLU.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.merkblattFuellUndWaschplaetzeLU.text')}
      </a>,
      <a href={t('elearning.doc.merkblattFuellUndWaschplaetzeSO.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.merkblattFuellUndWaschplaetzeSO.text')}
      </a>,
      <a href={t('elearning.doc.merkblattUmgangPSMTG.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.merkblattUmgangPSMTG.text')}
      </a>,
      <a href={t('elearning.doc.entscheidungshilfeZurErstellungVonFuellUndWaschplaetzen.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.entscheidungshilfeZurErstellungVonFuellUndWaschplaetzen.text')}
      </a>,
      <a href={t('elearning.doc.baulicherUmweltschutz.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.baulicherUmweltschutz.text')}
      </a>,
      <a href={t('elearning.doc.ansprechpersonenWaschBefuellplatzKOLAS.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.ansprechpersonenWaschBefuellplatzKOLAS.text')}
      </a>,
      <a href={t('elearning.doc.mustervereinbarungBehandlungssystemeSG.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.mustervereinbarungBehandlungssystemeSG.text')}
      </a>,
      <a href={t('elearning.doc.mustervereinbarungWaschplatzSG.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.mustervereinbarungWaschplatzSG.text')}
      </a>,
    ],
    links: [
      <a href={t('elearning.link.glpBehandlungssysteme.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.link.glpBehandlungssysteme.text')}
      </a>,
      <a href={t('elearning.link.FuellUndWaschplaetzeZH.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.link.FuellUndWaschplaetzeZH.text')}
      </a>,
      <a href={t('elearning.link.FuellUndWaschplaetzeSG.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.link.FuellUndWaschplaetzeSG.text')}
      </a>,
    ],
  });
}
