import React from 'react';
import { Container, Row } from 'react-bootstrap';
import Footer from '../Footer';
import Navigation from '../navigation/Navigation';

type LayoutProps = {
    children: React.ReactNode,
    setUser: any
}

function Layout({
  children,
  setUser,
}: LayoutProps) {
  return (
    <div>
      <Row>
        <Navigation
          id="top-menu"
          setUser={setUser}
        />
      </Row>
      <Row>
        <main id="content" className="main">
          <Container>
            {children}
          </Container>
        </main>
      </Row>
      <Row>
        <Footer
          id="footer"
          className="footer"
        />
      </Row>
    </div>
  );
}

export default Layout;
