/* eslint-disable react/no-array-index-key */
/**
* TODOS:
*  - Add form validation
*/
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  FieldArray, Formik, FormikHelpers,
} from 'formik';
import {
  Form, Button, Col, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { dataService } from '../../services/DataService';
import AnswerOption from './AnswerOption';
import ConditionFormField from './ConditionFormField';
import FormImage from './FormImage';

interface Values {
    question: string,
    id: string,
    topic: string,
    order: string,
    type: string,
    creationDate: string,
    dependsOnQuestion: string,
    dependsOnAnswer: string,
    conditionOperator: string,
    conditions: Array<Record<string, any>>,
    options: Array<any>,
    imageSrc: string,
}

export default function QuestionForm() {
  const { id } = useParams();
  const [questions, setQuestions] = useState<Array<Record<string, any>>>([]);
  const [values, setValues] = useState<Values>({
    question: '',
    id: '',
    topic: '',
    order: '',
    type: '',
    creationDate: '',
    conditions: [],
    dependsOnQuestion: '',
    dependsOnAnswer: '',
    conditionOperator: '',
    options: [],
    imageSrc: '',
  });

  useEffect(() => {
    dataService.getItems('questions').then((res) => {
      if (res.statusCode === 200) {
        setQuestions(res.body);
      }
    });
    if (id) {
      dataService.getItem('questions', id).then((res: any) => {
        setValues(res.body);
      });
    }
  }, []);

  return (
    <>
      <h1>Fragen-Editor</h1>
      <Formik
        initialValues={values}
        enableReinitialize
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>,
        ) => {
          setTimeout(() => {
            dataService.setItem('questions', values.id, values);
            setSubmitting(false);
          }, 500);
        }}
      >
        {(formik: Record<string, any>) => (
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3" controlId="question">
              <Form.Label>Frage</Form.Label>
              <Form.Control
                type="text"
                placeholder="Wie lautet die Frage?"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.question}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="question_fr">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="text"
                placeholder="Quelle est la question ?"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.question_fr}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="question_it">
              <Form.Label>Domanda</Form.Label>
              <Form.Control
                type="text"
                placeholder="Qual è la domanda?"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.question_it}
              />
            </Form.Group>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="id">
                  <Form.Label>ID</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="eindeutige ID"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.id}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="topic">
                  <Form.Label>Modul</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Zu welchem Modul gehört die Frage"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.topic}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="order">
                  <Form.Label>Position</Form.Label>
                  <Form.Control
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.order}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="type">
                  <Form.Label>Fragen-Typ</Form.Label>
                  <Form.Select
                    aria-label="Fragen-Typ"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.type}
                  >
                    <option>Bitte auswählen</option>
                    <option value="checkbox">Multiple-Choice</option>
                    <option value="radio">Single-Choice</option>
                    <option value="select">Dropdown</option>
                    <option value="answerRanking">Antwort-Ranking</option>
                    <option value="inputField">Input-Field</option>
                    <option value="computedQuestion">Berechnung mit anderer Frage</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="creationDate">
                  <Form.Label>Erstellungsdatum</Form.Label>
                  <Form.Control
                    type="date"
                    readOnly
                    disabled
                    value={formik.values.creationDate}
                  />
                </Form.Group>
              </Col>
            </Row>
            <p>Bedingungen</p>
            <FieldArray
              name="conditions"
              render={(arrayHelpers) => (
                <>
                  {(formik.values.conditions)
                    ? formik.values.conditions
                      .map((condition: Array<Record<string, any>>, index: number) => (
                        <section className="section">
                          <ConditionFormField
                            formik={formik}
                            index={index}
                            questions={questions}
                            key={index}
                          />
                        </section>
                      ))
                    : (
                      <Row className="mb-3">
                        <Col>
                          <Form.Group controlId="dependsOnQuestion">
                            <Form.Select
                              aria-label="Bedingt Frage"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.dependsOnQuestion}
                            >
                              <option>Bedingt Frage:</option>
                              {questions.map((question) => (
                                <option value={question.id}>{question.id}</option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="dependsOnAnswer">
                            <Form.Select
                              aria-label="Wenn Antwort"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.dependsOnAnswer}
                            >
                              <option>Wenn Antwort:</option>
                              {(formik.values.dependsOnQuestion !== '')
                                ? questions
                                  .filter((question) => question.id === formik.values.dependsOnQuestion)[0]?.options
                                  .map((answer: Record<string, string>) => (
                                    <option value={answer.id}>{answer.text}</option>
                                  ))
                                : null}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="conditionOperator">
                            <Form.Select
                              aria-label="Operator"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.conditionOperator}
                            >
                              <option>Operator:</option>
                              <option value="and">AND</option>
                              <option value="or">OR</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                  <Row>
                    <Col>
                      <Button
                        variant="outline-primary"
                        title="Bedingung entfernen"
                        onClick={() => arrayHelpers.remove(-1)}
                      >
                        -
                      </Button>
                      <Button
                        variant="outline-primary"
                        title="Bedingung hinzufügen"
                        onClick={() => arrayHelpers.push({})}
                      >
                        +
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            />
            {/* <Row className="mb-3">
              <Col>
                <Form.Group controlId="dependsOnQuestion">
                  <Form.Select
                    aria-label="Bedingt Frage"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.dependsOnQuestion}
                  >
                    <option>Bedingt Frage:</option>
                    {questions.map((question) => (
                      <option value={question.id}>{question.id}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="dependsOnAnswer">
                  <Form.Select
                    aria-label="Wenn Antwort"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.dependsOnAnswer}
                  >
                    <option>Wenn Antwort:</option>
                    {(formik.values.dependsOnQuestion !== '')
                      ? questions
                        .filter((question) => question.id === formik.values.dependsOnQuestion)[0]?.options
                        .map((answer: Record<string, string>) => (
                          <option value={answer.text}>{answer.text}</option>
                        ))
                      : null}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="conditionOperator">
                  <Form.Select
                    aria-label="Operator"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.conditionOperator}
                  >
                    <option>Operator:</option>
                    <option value="and">AND</option>
                    <option value="or">OR</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row> */}
            <h3>Antwortmöglichkeiten</h3>
            <FieldArray
              name="options"
              render={(arrayHelpers) => (
                <>
                  {formik.values.options
                    .map((option: Array<Record<string, any>>, index: number) => (
                      <section className="section">
                        <AnswerOption
                          questions={questions}
                          formik={formik}
                          index={index}
                          key={index}
                        />
                        <Button
                          variant="outline-primary"
                          title="Diese Antwortmöglichkeit entfernen"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          -
                        </Button>
                      </section>
                    ))}
                  <Button
                    variant="outline-primary"
                    title="Antwortmöglichkeit hinzufügen"
                    onClick={() => arrayHelpers.push({})}
                  >
                    +
                  </Button>
                </>
              )}
            />
            <FormImage formik={formik} />
            <Row>
              <Col>
                <div className="sct-form-button-nav">
                  <Button variant="primary" type="submit">
                    Speichern
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
}
