import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';

interface Props {
    pageTitle: string,
    moduleImgWrapperClassName?: string,
    moduleImgClassName?: string,
    imgAlt?: string,
    imgSrc?: string
}

export default function TitleBar({
  pageTitle, moduleImgWrapperClassName, moduleImgClassName, imgAlt, imgSrc,
}: Props) {
  return (
    <Row>
      <section className="module-title-section">
        {(imgSrc)
          ? (
            <Col lg={1}>
              <div className={moduleImgWrapperClassName}>
                <Image className={moduleImgClassName} alt={imgAlt} src={imgSrc} />
              </div>
            </Col>
          )
          : null}
        <Col lg>
          <h1>{pageTitle}</h1>
        </Col>
      </section>
    </Row>
  );
}

TitleBar.defaultProps = {
  moduleImgWrapperClassName: null,
  moduleImgClassName: null,
  imgAlt: null,
  imgSrc: null,
};
