import React, { useEffect, useState } from 'react';
import {
  Col, Form, FormGroup, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from './reducer/reduxHooks';
import validateInput from '../utils/validateInput';

interface OrderedAnswersProps {
  questionId: string,
  question: string,
  order: number,
  options: Array<Record<string, any>>,
  subtitle?: string,
  moduleName: string,
  children: string,
  currentAssessmentState: {[key: string]: any},
  setButtonActive: any,
}

export default function OrderedAnswers({
  questionId,
  question,
  order,
  options,
  subtitle,
  moduleName,
  children,
  currentAssessmentState,
  setButtonActive,
}: OrderedAnswersProps) {
  const { i18n, t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const childrenArray = children.split('| ');
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    setButtonActive(
      validateInput(currentAssessmentState.assessment[questionId].answers, 'answerRanking'),
    );
  }, [currentAssessmentState]);

  function occurenceCount(
    el: string | number | boolean,
    array: Array<string | number | boolean>,
  ) {
    let count = 0;
    const pos: Array<number> = [];
    array.forEach((arrayEl, idx) => {
      if (el === arrayEl) {
        count += 1;
        pos.push(idx);
      }
    });
    return [count, pos];
  }

  function hasDuplicates(
    array: Array<string | number | boolean>,
    exclude: string | number | boolean | null = null,
  ) {
    let filteredArray;
    if (exclude !== null) {
      filteredArray = array.filter((el) => el !== exclude);
    } else {
      filteredArray = array;
    }
    return filteredArray.length !== new Set(filteredArray).size;
  }

  function uniqueInput() {
    const formSelect = Array.from(document.querySelectorAll('.ordered-answer-select'));
    const answers = formSelect
      .map((select: Record<string, any>) => select.options[select.selectedIndex].value);
    const error = hasDuplicates(answers, '-');
    answers.forEach((answer, idx) => {
      if (answer !== '-') {
        const [count] = occurenceCount(answer, answers);
        if (count > 1) {
          formSelect[idx].classList.add('form-validation-error');
        } else {
          formSelect[idx].classList.remove('form-validation-error');
        }
      }
    });
    if (error) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }

  function renderValidationError(msg: string) {
    return (
      <p>
        <span className="form-validation-error-message">{msg}</span>
      </p>
    );
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement | any>): void => {
    const [answer] = options.filter((option) => option.id === e.currentTarget.id);
    // Dispatch to redux store
    const payload = {
      id: questionId,
      order,
      customQuestionType: 'answerRanking',
      text: question,
      answers: {
        id: e.currentTarget.id,
        text: answer.text,
        text_fr: answer.text_fr,
        text_it: answer.text_it,
        value: e.currentTarget.value,
      },
    };
    dispatch({ type: 'currentAssessment/updateInput', payload, moduleName: moduleName.toLowerCase() });
    // Validation
    uniqueInput();
  };

  function createSelectOption(option: string, key: number) {
    return (
      <option
        key={key}
        value={option}
      >
        {option}
      </option>
    );
  }

  function createSelectInput(option: string, answerId: string, key: number) {
    const [answer] = currentAssessmentState.assessment[questionId].answers
      .filter((answerObj: Record<string, any>) => answerObj.id === answerId);
    let value;
    if (answer) {
      value = answer.value;
    } else {
      value = null;
    }
    return (
      <Form.Group as={Row} className="mb-3">
        <Col xs="auto">
          <Form.Select
            as={Row}
            className="mb-3 ordered-answer-select"
            onChange={(e) => handleSelectChange(e)}
            name={option}
            id={answerId}
            key={key}
            value={(currentAssessmentState.assessment[questionId]
              && currentAssessmentState.assessment[questionId].answers)
              ? value
              : null}
          >
            <option>-</option>
            {childrenArray.map((_, idx) => {
              const num = idx + 1;
              return createSelectOption(String(num), num);
            })}
          </Form.Select>
        </Col>
        <Form.Label column>{option}</Form.Label>
      </Form.Group>
    );
  }

  function createAnswers(): Array<React.ReactNode> {
    return options.map((option, index) => {
      const text = (i18n.language !== 'de')
        ? option[`text_${i18n.language}`] ?? option.text
        : option.text;
      return createSelectInput(text, option.id, index + 1);
    });
  }

  return (
    <>
      <FormGroup>
        <h3>{question}</h3>
        <h4><em>{subtitle}</em></h4>
        {createAnswers()}
      </FormGroup>
      {(hasError) ? renderValidationError(t('form.validation.duplicates')) : null}
    </>
  );
}

OrderedAnswers.defaultProps = {
  subtitle: null,
};
