/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

// Define a type for the slice state
interface questionState {
  questions: Record<string, any>,
}

// Define the initial state using that type
const initialState: questionState = {
  questions: {},
};

export const questionSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    questionsLoaded: (state, action) => {
      state.questions[action.payload.payload.moduleId] = action.payload.payload.questions;
    },
  },
});

export const {
  questionsLoaded,
} = questionSlice.actions;

export default questionSlice.reducer;
