import React from 'react';
import { Trans } from 'react-i18next';
import RiskBar from './RiskBar';

interface Props {
    module: string,
    summaryRiskRating: Record<string, number>
}

export default function SummaryRiskBar({
  module,
  summaryRiskRating,
}: Props) {
  return (
    <div className="summary-risk-wrapper">
      <div className="summary-risk-title">
        <h4>
          <Trans i18nKey="result.riskbar.intro" ns="common">
            Risikobewertung Ihrer aktuellen Praxis im Bereich&nbsp;
            {{ module }}
            :
          </Trans>
        </h4>
      </div>
      <RiskBar summaryRiskRating={summaryRiskRating} />
    </div>
  );
}
