import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Image } from 'react-bootstrap';

type moduleProps = {
    moduleImgWrapperClassName: string,
    moduleImgClassName: string,
    module: Record<string, any>,
    children: React.ReactNode
}

export default function Module({
  moduleImgWrapperClassName,
  moduleImgClassName,
  module,
  children,
}: moduleProps) {
  const { i18n } = useTranslation('common');
  const moduleName = (i18n.language !== 'de') ? module[`title_${i18n.language}`] ?? module.title : module.title;
  return (
    <>
      <Row>
        <section className="module-title-section">
          <Col lg={1}>
            <div className={moduleImgWrapperClassName}>
              <Image className={moduleImgClassName} alt={`Icon ${moduleName}`} src={module.imgSrc} />
            </div>
          </Col>
          <Col lg>
            <h1>{(i18n.language !== 'de') ? module[`title_${i18n.language}`] ?? module.title : module.title}</h1>
          </Col>
        </section>
      </Row>
      <Row>
        <Col>
          {children}
        </Col>
      </Row>
    </>
  );
}
