import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import 'bootstrap-icons/font/bootstrap-icons.css';
import commonDE from './translations/de/common.json';
import commonIT from './translations/it/common.json';
import commonFR from './translations/fr/common.json';
import store from './components/store';
import { fetchModules } from './components/reducer/thunks';
import App from './App';
import './scss/index.scss';

store.dispatch<any>(fetchModules);

const options = {
  order: ['cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'navigator'],
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    detection: options,
    interpolation: { escapeValue: false }, // React already does escaping
    defaultNS: 'common',
    fallbackLng: 'de',
    supportedLngs: ['de', 'it', 'fr'],
    resources: {
      fr: { common: commonFR },
      it: { common: commonIT },
      de: { common: commonDE },
    },
  });

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
