import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SummaryRiskBar from './SummaryRiskBar';
import ResultLegend from './ResultLegend';
import RenderCommentary from './RenderCommentary';

interface Props {
  summaryRiskRating: Record<string, number> | null,
  moduleTitle: string
}

export default function ResultSummary({
  summaryRiskRating,
  moduleTitle,
}: Props) {
  if (summaryRiskRating) {
    const { t } = useTranslation('common');
    return (
      <>
        <div className="summary-title">
          <h3>{t('result.summary.title')}</h3>
        </div>
        <div className="summary-intro">
          <Trans i18nKey="result.summary.intro" ns="common">
            <p>
              Im Folgenden sehen Sie die Bewertung Ihres Umgangs mit Pflanzenschutzmitteln im Bereich «
              {{ moduleTitle }}
              » in Bezug auf den Gewässerschutz entsprechend der von Ihnen gemachten Angaben.
            </p>
          </Trans>
        </div>
        <div className="summary-body-wrapper">
          <SummaryRiskBar
            summaryRiskRating={summaryRiskRating}
            module={moduleTitle}
          />
          <RenderCommentary
            summaryRiskRating={summaryRiskRating}
            module={moduleTitle}
          />
        </div>
        <ResultLegend />
      </>
    );
  }

  return null;
}
