import React, { useEffect } from 'react';
import {
  Col, Form, Row, FloatingLabel,
} from 'react-bootstrap';
import generateUniqueId from '../../utils/generateUniqueId';

interface AnswerOptionProps {
    index: number
    formik: Record<string, any>,
    key: number,
    questions: Array<any>,
}

export default function AnswerOption({
  index, formik, key, questions,
}: AnswerOptionProps) {
  const ind = index;

  useEffect(() => {
    const allAnswerIds = questions
      .map((question: Record<string, any>) => question.options
        .map((option: Record<string, any>) => option.id));

    if (!formik.values.options[ind].id) {
      /* if formik.values.options[ind].id (answer id) is falsy
       a unique id will be generated and set */
      formik.setFieldValue(`options[${ind}].id`, generateUniqueId(allAnswerIds));
    }
  }, []);

  return (
    <>
      <Row className="mb-3">
        <Col>
          <Form.Group className="mb-3" controlId={`options[${ind}].text`}>
            <Form.Label>
              Antwort
              {(formik.values.type === 'inputField') ? ' (wird nicht angezeigt!)' : null}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Antwort"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.options[ind].text}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId={`options[${ind}].text_fr`}>
            <Form.Label>
              Réponse
              {(formik.values.type === 'inputField') ? ' (wird nicht angezeigt!)' : null}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Réponse"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.options[ind].text_fr}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId={`options[${ind}].text_it`}>
            <Form.Label>
              Risposta
              {(formik.values.type === 'inputField') ? ' (wird nicht angezeigt!)' : null}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Risposta"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.options[ind].text_it}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="2">
          <Form.Group controlId={`options[${ind}].id`}>
            <Form.Label>ID</Form.Label>
            <Form.Control
              type="text"
              readOnly
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.options[ind].id}
            />
          </Form.Group>
        </Col>
        {(['inputField'].includes(formik.values.type))
          ? (
            <Col md="2">
              <Form.Group controlId={`options[${ind}].inputType`}>
                <Form.Label>Input-Typ</Form.Label>
                <Form.Select
                  aria-label="Input type"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.options[ind].inputType}
                >
                  <option>Bitte wählen:</option>
                  <option value="number">Zahl</option>
                  <option value="text">Text</option>
                </Form.Select>
              </Form.Group>
            </Col>
          )
          : null}
        {(['radio', 'checkbox', 'select'].includes(formik.values.type))
          ? (
            <Col md="2">
              <Form.Group controlId={`options[${ind}].correct`}>
                <Form.Label>Antwort ist ...</Form.Label>
                <Form.Select
                  aria-label="Fragen-Typ"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.options[ind].correct}
                >
                  <option>Bitte wählen</option>
                  <option value="neutral">neutral</option>
                  <option value="false">falsch</option>
                  <option value="true">richtig</option>
                </Form.Select>
              </Form.Group>
            </Col>
          )
          : null}
        {(formik.values.type === 'answerRanking')
          ? (
            <Col md="2">
              <Form.Group controlId={`options[${ind}].position`}>
                <Form.Label>Antwort-Position ...</Form.Label>
                <Form.Select
                  aria-label="Fragen-Typ"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.options[ind].position}
                >
                  <option>Bitte wählen</option>
                  {formik.values.options
                    .map((_: any, idx: number) => <option value={idx + 1}>{idx + 1}</option>)}
                </Form.Select>
              </Form.Group>
            </Col>
          )
          : null}
        <Col md="2">
          <Form.Group controlId={`options[${ind}].risk`}>
            <Form.Label>Risiko-Bewertung</Form.Label>
            <Form.Select
              aria-label="Risiko-Kategorie"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.options[ind].risk}
            >
              <option>Bitte auswählen</option>
              <option value="orange">orange</option>
              <option value="rot">rot</option>
              {(formik.values.type === 'radio') ? <option value="grün">grün</option> : null}
              <option value="neutral">neutral</option>
            </Form.Select>
          </Form.Group>
        </Col>
        {(formik.values.type === 'computedQuestion' && questions)
          ? (
            <>
              <Col md="2">
                <Form.Group controlId={`options[${ind}].relatedQuestion`}>
                  <Form.Label>Auswertung mit ...</Form.Label>
                  <Form.Select
                    required
                    aria-label="Auswertung mit ..."
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.options[ind].relatedQuestion}
                  >
                    <option>Bitte wählen:</option>
                    {questions.map((question) => (
                      <option value={question.id}>{question.id}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md="2">
                <Form.Group controlId={`options[${ind}].operation`}>
                  <Form.Label>Operation</Form.Label>
                  <Form.Select
                    required
                    aria-label="Operation"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.options[ind].operation}
                  >
                    <option>Bitte wählen:</option>
                    <option value="percentage">Prozentsatz</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md="2">
                <Form.Group controlId={`options[${ind}].thresholdOperator`}>
                  <Form.Label>Threshold operator</Form.Label>
                  <Form.Select
                    aria-label="Threshold Operator"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.options[ind].thresholdOperator}
                  >
                    <option>Bitte wählen:</option>
                    <option value=">">&gt;</option>
                    <option value=">=">&gt;=</option>
                    <option value="<=">&lt;=</option>
                    <option value="<">&lt;</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md="2">
                <Form.Group controlId={`options[${ind}].threshold`}>
                  <Form.Label>Schwellenwert</Form.Label>
                  <Form.Control
                    type="number"
                    width="15"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.options[ind].threshold}
                  />
                </Form.Group>
              </Col>
              <br />
            </>
          )
          : null}
      </Row>
      <Row>
        <Col md="2">
          <Form.Group className="mb-3" controlId={`options[${ind}].showImage`}>
            <Form.Check
              aria-label="Bild anzeigen"
              checked={formik.values.options[ind].showImage}
              label="Bild anzeigen"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.options[ind].showImage}
            />
          </Form.Group>
        </Col>
        {(formik.values.options[ind].showImage)
          ? (
            <Col>
              <Form.Group className="mb-3" controlId={`options[${ind}].answerImageSrc`}>
                <Form.Label>Bildquelle (src)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Image source"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.options[ind].answerImageSrc}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId={`options[${ind}].answerImageSrc_fr`}>
                <Form.Label>Source de l&apos;image (src)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Source de l'image (src)"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.options[ind].answerImageSrc_fr}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId={`options[${ind}].answerImageSrc_it`}>
                <Form.Label>Fonte immagine (src)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Fonte immagine (src)"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.options[ind].answerImageSrc_it}
                />
              </Form.Group>
            </Col>
          )
          : null}
      </Row>
      <Row>
        <Col>
          <FloatingLabel controlId={`options[${ind}].feedback`} label="Erklärung" className="mb-3">
            <Form.Control
              as="textarea"
              placeholder="Feedback"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.options[ind].feedback}
              style={{ height: '150px' }}
            />
          </FloatingLabel>
          <FloatingLabel controlId={`options[${ind}].feedback_fr`} label="Explication" className="mb-3">
            <Form.Control
              as="textarea"
              placeholder="Explication"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.options[ind].feedback_fr}
              style={{ height: '150px' }}
            />
          </FloatingLabel>
          <FloatingLabel controlId={`options[${ind}].feedback_it`} label="Spiegazione" className="mb-3">
            <Form.Control
              as="textarea"
              placeholder="Spiegazione"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.options[ind].feedback_it}
              style={{ height: '150px' }}
            />
          </FloatingLabel>
        </Col>
      </Row>
    </>
  );
}
