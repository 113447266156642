/**
 * TODOS:
 * - Nutzer dürfen submitted status nicht ändern können bzw. nicht das Assessment wiederholen
 *
 */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../reducer/reduxHooks';
import Module from '../modules/Module';
import AssessmentQuestions from './AssessmentQuestions';
import Results from '../results/Results';

export default function ModuleAssessment() {
  const { moduleId } = useParams();
  const [module] = useAppSelector((state) => state.moduleState.modules
    .filter((module: Record<string, any>) => module.id === moduleId));
  const lessonState = (moduleId)
    ? useAppSelector((state) => state.lessonState.lessons[module.id])
    : null;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (moduleId) {
      setIsLoading(false);
    }
  }, []);

  const children = () => {
    if (!lessonState?.submitted || lessonState?.isRetake) {
      return (
        <AssessmentQuestions
          module={module}
        />
      );
    }
    return (
      <Results
        module={module}
        collection={(lessonState?.retakesEnabled) ? 'retakes' : 'assessments'}
        showModuleOverview={false}
      />
    );
  };

  return (
    <Module
      moduleImgWrapperClassName="module-img-wrapper"
      moduleImgClassName="module-img"
      module={module}
    >
      <section className="section">
        {children()}
      </section>
    </Module>
  );
}
