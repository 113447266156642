import React from 'react';
import {
  Col, Form, Row,
} from 'react-bootstrap';

interface ImageProps {
  formik: Record<string, any>,
}

export default function FormImage({
  formik,
}: ImageProps) {
  return (
    <>
      <h3>Bild einfügen</h3>
      <Row className="mb-3">
        <Col>
          <Form.Group className="mb-3" controlId="imageSrc">
            <Form.Label>Bildquelle (src)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Image source"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.imageSrc}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="imageSrc_fr">
            <Form.Label>Source de l&apos;image (src)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Source de l'image"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.imageSrc_fr}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="imageSrc_it">
            <Form.Label>Fonte immagine (src)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Fonte immagine"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.imageSrc_it}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}
