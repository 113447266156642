import isSingleChoiceCorrect from './isSingleChoiceCorrect';
import isSelectCorrect from './isSelectCorrect';

export default function aggrQuestionRating(question: Record<string, any>) {
  const ratings: Array<string> = [];

  if (question.customQuestionType === 'radio' && isSingleChoiceCorrect(question.answers)) {
    return 'grün';
  }

  if (question.customQuestionType === 'select' && isSelectCorrect(question.answers)) {
    return 'grün';
  }

  if (['answerRanking', 'inputField', 'computedQuestion'].includes(question.customQuestionType)) {
    question.answers.forEach((answer: Record<string, any>) => {
      if (answer.correct === false || answer.risk === 'neutral') {
        ratings.push(answer.risk);
      }
    });
  } else if (question.customQuestionType === 'select') {
    question.answers.forEach((answer: Record<string, any>) => {
      if ((answer.selected === true && answer.correct === false) || (answer.selected === false && answer.correct === true) || answer.risk === 'neutral') {
        ratings.push(answer.risk);
      }
    });
  } else if (question.customQuestionType === 'radio') {
    // check if any answer is checked
    const questionChecked = question.answers
      .filter((answer: Record<string, any>) => answer.checked === true);

    if (questionChecked.length > 0) {
      // if an answer is checked return only that answer's risk
      question.answers.forEach((answer: Record<string, any>) => {
        if ((answer.checked === true && answer.correct === false) || (answer.checked === false && answer.correct === true) || answer.risk === 'neutral') {
          if (answer.checked === true) {
            ratings.push(answer.risk);
          }
        }
      });
    } else {
      question.answers.forEach((answer: Record<string, any>) => {
        ratings.push(answer.risk);
      });
    }
  } else {
    question.answers.forEach((answer: Record<string, any>) => {
      if ((answer.checked === true && answer.correct === false) || (answer.checked === false && answer.correct === true) || answer.risk === 'neutral') {
        ratings.push(answer.risk);
      }
    });
  }

  if (ratings.filter((r) => r === 'rot').length > 0) {
    return 'rot';
  }
  if (ratings.filter((r) => r === 'orange').length > 0) {
    return 'orange';
  }
  if (ratings.filter((r) => r === 'neutral').length > 0) {
    return 'neutral';
  }
  // default risk: grün
  return 'grün';
}
