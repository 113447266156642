import {
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  setPersistence,
} from 'firebase/auth';
import setUser from './setUser';

interface signUpProps {
    email: string,
    password: string,
    userGroup: string,
    name: string,
    lastName: string
}

export default async function signUp({
  email, password, userGroup, name, lastName,
}: signUpProps) {
  const auth = getAuth();
  await setPersistence(auth, browserSessionPersistence);
  const res = await createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => (
      // Signed in
      {
        statusCode: 201,
        body: userCredential,
      }))
    .catch((error) => ({
      statusCode: error.code,
      body: error.message,
    }));

  if (res.statusCode === 201) {
    sendEmailVerification(res.body.user);
    setUser({
      userCredential: res.body,
      userGroup,
      name,
      lastName,
    });
  }
  return res;
}
