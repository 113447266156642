import React from 'react';
import { useTranslation } from 'react-i18next';
import UserProfile from './UserProfile';
import TitleBar from '../layout/PageTitle';

export default function Profile() {
  const { t, i18n } = useTranslation('common');
  return (
    <>
      <TitleBar pageTitle={t('pages.profile.title')} />
      <UserProfile />
    </>
  );
}
