/**
 * NEEDS IMPROVEMENT!!!
 * Handling of AND and OR conditions is not yet implemented
 * and will cause an uncatched error or incorrect behavior !!!
 *
 * Multiple condition with AND and OR needs to be handled
 * in correct order:
 * i.e.:
 * (foo AND bar) OR baz
 * (foo AND bar) OR (baz OR xyz)
 *
 * Operators are in the objects
 *
 * @param question object
 * @param answerObject object
 * @returns boolean
 */

import compareValues from './compareValues';

export default function questionMeetsCondition(
  question: Record<string, any>,
  answerObject: Record<string, any>,
) {
  if (question.conditions?.length > 0) {
    const { conditions } = question;
    if (conditions.length > 1) {
      // if there is more than one condition
      const operators: Array<string> = [];
      const evalResult = conditions.map((condition: Record<string, any>) => {
        const operator = condition.operator || null;
        const { dependsOnQuestion, dependsOnAnswer } = condition;
        const [givenAnswer] = answerObject[dependsOnQuestion].answers
          .filter((answer: Record<string, any>) => answer.id === dependsOnAnswer);

        if (operator) {
          operators.push(operator);
        }

        if (givenAnswer?.checked === true || givenAnswer?.selected === true) {
          return true;
        }
        return false;
      });
      const isTrue = (currentValue: boolean) => currentValue === true;
      if (operators[0] === 'and') {
        return evalResult.every(isTrue);
      }
      return evalResult.some(isTrue);
    }
    // if there is only one condition
    const [givenAnswer] = answerObject[conditions[0].dependsOnQuestion].answers
      .filter((answer: Record<string, any>) => answer.id === conditions[0].dependsOnAnswer);

    // if thresholdOperator exists the answer will be evaluated with checkValue function
    if (Object.prototype.hasOwnProperty.call(conditions[0], 'thresholdOperator')) {
      const { thresholdOperator, dependsOnAnswerValue } = conditions[0];
      const answerValue = givenAnswer.value;
      return compareValues(+answerValue, thresholdOperator, +dependsOnAnswerValue);
    }

    // if thresholdOperator does not exist, check if the corresponding answer was selected
    if (givenAnswer?.checked === true || givenAnswer?.selected === true) {
      return true;
    }
    return false;
  }
  // handling deprecated data model (with only one condition)
  if (answerObject[question.dependsOnQuestion]) {
    if (answerObject[question.dependsOnQuestion].answers[question.dependsOnAnswer] === true) {
      return true;
    }
    return false;
  }
  return true;
}
