import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type ModuleProps = {
  title?: string,
}

export default function LogoSection(props: ModuleProps) {
  const { t } = useTranslation('common');
  const { title } = props;
  return (
    <>
      {(title)
        ? (
          <Row>
            <Col>
              <h2>{title}</h2>
            </Col>
          </Row>
        )
        : null}
      <Row className="logo-wrapper">
        <Col md="4">
          <a href="https://www.agridea.ch" target="_blank" rel="noreferrer">
            <img alt={t('logo.altText.logoAgridea') ?? undefined} className="logo" src="/assets/img/Logo-Agridea.png" />
          </a>
        </Col>
        <Col md="4">
          <a href="https://pflanzenschutzmittel-und-gewaesser.ch/" target="_blank" rel="noreferrer">
            <img alt={t('logo.altText.logoPPG') ?? undefined} className="logo" src="/assets/img/Logo PPG_de_NEU_FETT.png" />
          </a>
        </Col>
        <Col md="4">
          <a href="https://www.bfh.ch/hafl/" target="_blank" rel="noreferrer">
            <img alt={t('logo.altText.logoHAFL') ?? undefined} className="logo" src="/assets/img/BFH-Logo.png" />
          </a>
        </Col>
      </Row>
    </>
  );
}

LogoSection.defaultProps = {
  title: null,
};
