import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import isSingleChoiceCorrect from '../../utils/isSingleChoiceCorrect';
import returnRenderClass from '../../utils/returnRenderClass';
import RenderAnswerRanking from './RenderAnswerRanking';
import RenderInputField from './RenderInputField';
import RenderSelect from './RenderSelect';

interface Props {
  answers: Array<Record<string, any>>
}

export default function RenderAnswers(props: Props) {
  const { t } = useTranslation('common');
  const { answers } = props;

  const checkedAnswers = answers.filter((answer: Record<string, any>) => answer.checked === true);
  const answerTitle = (checkedAnswers.length < 2) ? t('result.details.answerTitle') : t('result.details.answerTitle_plural');

  // if single choice answer has more than one correct answer and one is checked return true
  const singleChoiceCorrect = isSingleChoiceCorrect(answers);

  const { questionType } = answers[0];
  if (questionType === 'answerRanking') {
    return <RenderAnswerRanking answerTitle={answerTitle} answers={answers} />;
  }
  if (['inputField', 'computedQuestion'].includes(questionType)) {
    return <RenderInputField answerTitle={answerTitle} answers={answers} />;
  }
  if (questionType === 'select') {
    return <RenderSelect answerTitle={answerTitle} answers={answers} />;
  }

  return (
    <>
      {answers.map((answer: Record<string, any>, ind: number) => {
        const answerText = (i18next.language !== 'de') ? answer[`text_${i18next.language}`] ?? answer.text : answer.text;
        if (answer.checked === true) {
          return (
            <>
              {(ind === 0) ? <h4 className="highlighted">{answerTitle}</h4> : null}
              <ul className="no-list-style">
                <li>
                  <span className={returnRenderClass(answer)}>
                    <i className={returnRenderClass(answer, true)} />
                    {' '}
                    {answerText}
                  </span>
                </li>
              </ul>
            </>
          );
        }
        if (answer.questionType === 'radio' && singleChoiceCorrect === true) {
          return (
            <>
              {(ind === 0) ? <h4 className="highlighted">{answerTitle}</h4> : null}
              <ul className="no-list-style">
                <li>
                  <span className="blured">
                    <i className="blured bi bi-square" />
                    {' '}
                    {answerText}
                  </span>
                </li>
              </ul>
            </>
          );
        }
        return (
          <>
            {(ind === 0) ? <h4 className="highlighted">{answerTitle}</h4> : null}
            <ul className="no-list-style">
              <li>
                <span className="blured">
                  <i className={(answer.correct === true) ? 'true feedback highlighted bi bi-exclamation-square' : 'blured bi bi-square'} />
                  {' '}
                  {answerText}
                </span>
              </li>
            </ul>
          </>
        );
      })}
    </>
  );
}
