import { AnyAction, Dispatch, Store } from '@reduxjs/toolkit';

function unsetQuestion(
  store: Store,
  dispatch: Dispatch<AnyAction>,
  moduleId: string,
  questionId: string,
  type: string,
) {
  const questionState = store
    .getState().currentAssessmentState.currentAssessment[moduleId].assessment[questionId];
  const { answers } = questionState;

  answers.forEach((answer: Record<string, any>) => {
    let payload;
    switch (type) {
      case 'radio':
        payload = {
          id: questionId,
          text: questionState.text,
          order: questionState.order,
          answers: {
            id: answer.id,
            text: answer.text,
            checked: false,
          },
        };
        dispatch({ type: 'currentAssessment/updateRadio', payload, moduleName: moduleId });
        break;
      case 'select':
        payload = {
          id: questionId,
          text: questionState.text,
          order: questionState.order,
          answers: {
            id: answer.id,
            text: answer.text,
            selected: false,
          },
        };
        dispatch({ type: 'currentAssessment/updateSelect', payload, moduleName: moduleId });
        break;
      case 'number':
        payload = {
          id: questionId,
          text: questionState.text,
          order: questionState.order,
          answers: {
            id: answer.id,
            text: answer.text,
            value: '',
          },
        };
        dispatch({ type: 'currentAssessment/updateInput', payload, moduleName: moduleId });
        break;
      default: // checkbox type
        payload = {
          id: questionId,
          text: questionState.text,
          order: questionState.order,
          answers: {
            id: answer.id,
            text: answer.text,
            checked: false,
          },
        };
        dispatch({ type: 'currentAssessment/updateCheckbox', payload, moduleName: moduleId });
        break;
    }
  });
}

export default unsetQuestion;
