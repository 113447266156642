import React from 'react';

interface Props {
  answers: Array<Record<string, any>>,
  answerTitle: string,
}

function highlightResults(answer: Record<string, any>) {
  if (answer.correct === true && answer.selected === true) {
    return 'true highlighted';
  }
  if (answer.correct === true && answer.selected === false) {
    return 'highlighted';
  }
  if (answer.correct === false && answer.selected === true) {
    return 'false highlighted';
  }
  return 'blured';
}

export default function RenderSelect({ answers, answerTitle }: Props) {
  return (
    <>
      {answers.filter((answer: Record<string, any>) => answer.selected === true)
        .map((answer: Record<string, any>, ind: number) => (
          <>
            {(ind === 0) ? <h4 className="highlighted">{answerTitle}</h4> : null}
            <ul className="no-list-style">
              <li>
                <span className={highlightResults(answer)}>
                  {answer.text}
                </span>
              </li>
            </ul>
          </>
        ))}
    </>
  );
}
