import React from 'react';
import { Row, Col } from 'react-bootstrap';
import i18next from 'i18next';
import RenderRating from './RenderRating';
import RenderAnswers from './RenderAnswers';
import RenderFeedback from './RenderFeedback';

interface Props {
  questions: Array<Record<string, any>>
}

export default function RenderResults({ questions }: Props) {
  const orderedQuestions = [...questions]
    .sort((a: Record<string, any>, b: Record<string, any>) => a.order - b.order);

  return (
    <>
      {orderedQuestions
        .map((questionItem: Record<string, any>) => {
          const question = (i18next.language !== 'de')
            ? questionItem[`text_${i18next.language}`] ?? questionItem.text
            : questionItem.text;
          return (
            <div className={`list-item ${questionItem.order}`} key={questionItem.id}>
              <div className="list-item-title">
                <Row>
                  <Col>
                    <h4>{question}</h4>
                  </Col>
                  <Col>
                    <RenderRating question={questionItem} />
                  </Col>
                </Row>
              </div>
              <div className="list-item-body">
                <Row>
                  <Col>
                    <RenderAnswers answers={questionItem.answers} />
                  </Col>
                  <Col>
                    <RenderFeedback answers={questionItem.answers} />
                  </Col>
                </Row>
              </div>
            </div>
          );
        })}
    </>
  );
}
