import React from 'react';
import { CommentaryGoodPractice, CommentaryPoorPractice, CommentaryBadPractice } from './CommentaryOnResults';

interface Props {
    summaryRiskRating: Record<string, number>,
    module: string
}

export default function RenderCommentary({
  summaryRiskRating,
  module,
}: Props) {
  let comment;
  if (summaryRiskRating.success === 1.0) {
    comment = <CommentaryGoodPractice />;
  } else if (summaryRiskRating.danger === 0.0 && summaryRiskRating.success < 1.0) {
    comment = <CommentaryPoorPractice module={module} />;
  } else {
    comment = <CommentaryBadPractice module={module} />;
  }
  return (
    <div className="summary-description">
      {comment}
    </div>
  );
}
