import React from 'react';
import { useTranslation } from 'react-i18next';
import aggrQuestionRating from '../../utils/aggrQuestionRating';

interface Props {
    question: Record<string, any>
}

export default function RenderRating({ question }: Props) {
  const { t } = useTranslation('common');
  const rating = aggrQuestionRating(question);
  if (rating !== 'neutral') {
    return (
      <div className="ampel-wrapper">
        <div>{t('result.details.riskEvaluation')}</div>
        <div className={`ampel-licht redlight ${(rating === 'rot') ? 'on' : null}`} />
        <div className={`ampel-licht orangelight ${(rating === 'orange') ? 'on' : null}`} />
        <div className={`ampel-licht greenlight ${(rating === 'grün') ? 'on' : null}`} />
      </div>
    );
  }
  return null;
}
