import React from 'react';
import { useFormik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import selectUserFormValidationScheme from './selectUserFormValidationScheme';

interface FormArgs {
    buttonText: string,
    emailField: Record<string, any>,
    passwordField: boolean,
    submitHandler: (values: Record<string, any>) => Record<string, any>,
    formValues?: Record<string, any>|null
}

export default function UserForm({
  buttonText,
  emailField,
  passwordField,
  submitHandler,
  formValues,
}: FormArgs) {
  const validationScheme = selectUserFormValidationScheme(passwordField);
  const { t } = useTranslation('common');
  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
  } = useFormik({
    initialValues: formValues ?? {
      name: '',
      lastName: '',
      email: '',
      password: '',
      userGroup: '',
    },
    validationSchema: validationScheme,
    onSubmit: async (values) => {
      submitHandler(values);
    },
  });

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Form.Group className="mb-3" controlId="name">
        <Form.Label>{t('form.labels.firstName')}</Form.Label>
        <Form.Text className="required-field">{touched.name && errors.name}</Form.Text>
        <Form.Control
          type="text"
          placeholder={t('form.labels.firstName') ?? ''}
          {...getFieldProps('name')}
          className={errors.name ? 'invalid-field-value' : ''}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="lastName">
        <Form.Label>{t('form.labels.lastName')}</Form.Label>
        <Form.Text className="required-field">{touched.lastName && errors.lastName}</Form.Text>
        <Form.Control
          type="text"
          placeholder={t('form.labels.lastName') ?? ''}
          {...getFieldProps('lastName')}
          className={errors.lastName ? 'invalid-field-value' : ''}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="email">
        <Form.Label>{t('form.labels.email')}</Form.Label>
        <Form.Text className="required-field">{touched.email && errors.email}</Form.Text>
        <Form.Control
          type="email"
          placeholder={t('form.labels.email') ?? ''}
          disabled={!!(emailField?.disabled)}
          {...getFieldProps('email')}
          className={errors.email ? 'invalid-field-value' : ''}
        />
      </Form.Group>
      {(passwordField)
        ? (
          <Form.Group className="mb-3" controlId="password">
            <Form.Label>{t('form.labels.password')}</Form.Label>
            <Form.Text className="required-field">{touched.password && errors.password}</Form.Text>
            <Form.Control
              type="password"
              placeholder={t('form.labels.password') ?? ''}
              {...getFieldProps('password')}
              className={errors.password ? 'invalid-field-value' : ''}
            />
            <Form.Text className="text-muted">
              {t('form.validation.password.length')}
            </Form.Text>
          </Form.Group>
        )
        : null}
      <Form.Group controlId="userGroup">
        <Form.Label>{t('form.labels.fieldOfActivity')}</Form.Label>
        <Form.Text className="required-field">{touched.userGroup && errors.userGroup}</Form.Text>
        <Form.Select
          aria-label={t('form.labels.fieldOfActivity') ?? ''}
          {...getFieldProps('userGroup')}
          className={errors.userGroup ? 'invalid-field-value' : ''}
        >
          <option>{t('form.labels.pleaseSelect')}</option>
          <option value="farmManagement">{t('form.labels.activityOptions.farmManagement')}</option>
          <option value="operatingStaff">{t('form.labels.activityOptions.operatingStaff')}</option>
          <option value="contractor">{t('form.labels.activityOptions.contractor')}</option>
          <option value="consultancy">{t('form.labels.activityOptions.consultancy')}</option>
          <option value="education">{t('form.labels.activityOptions.education')}</option>
          <option value="enforcement">{t('form.labels.activityOptions.enforcement')}</option>
          <option value="authority">{t('form.labels.activityOptions.authority')}</option>
          <option value="industry">{t('form.labels.activityOptions.industry')}</option>
          <option value="gardening">{t('form.labels.activityOptions.gardening')}</option>
          <option value="forestry">{t('form.labels.activityOptions.forestry')}</option>
          <option value="other">{t('form.labels.activityOptions.other')}</option>
        </Form.Select>
      </Form.Group>
      <div className="sct-form-button-nav">
        <Button type="submit">{buttonText}</Button>
      </div>
    </Form>
  );
}

UserForm.defaultProps = {
  formValues: null,
};
