import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  answers: Array<Record<string, any>>,
  answerTitle: string,
}

export default function RenderAnswerRanking({ answers, answerTitle }: Props) {
  const { t } = useTranslation('common');
  return (
    <>
      {answers.sort((a, b) => a.position - b.position).map((answer: Record<string, any>, ind: number) => (
        <>
          {(ind === 0) ? <h4 className="highlighted">{t('result.details.answerRanking.title')}</h4> : null}
          <ul className="no-list-style">
            <li>
              <span className="highlighted">
                {`${answer.position}. `}
              </span>
              <span className={
                // eslint-disable-next-line no-nested-ternary
                (answer.correct === true)
                  ? 'true highlighted'
                  : (answer.risk === 'rot')
                    ? 'false highlighted'
                    : 'poor highlighted'
              }
              >
                {answer.text}
              </span>
              {' '}
              <Trans i18nKey="result.details.answerRanking.yourAnswer" ns="common">
                (Ihre Anwort:
                {{ answer: answer.value }}
                )
              </Trans>
            </li>
          </ul>
        </>
      ))}
    </>
  );
}
