import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Row, Col } from 'react-bootstrap';
import { useAppSelector } from '../reducer/reduxHooks';
import IsLoading from '../IsLoading';
import ModuleThumbnail from './ModuleThumbnail';

export default function Modules() {
  const modules = useAppSelector((state) => state.moduleState.modules);
  const assessments = useAppSelector((state) => state.lessonState.lessons);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (modules && assessments) {
      setIsLoading(false);
    }
  }, [modules, assessments]);

  return (
    <>
      <section className="section">
        <h1>{t('pages.modules.title')}</h1>
      </section>
      {(isLoading)
        ? <IsLoading />
        : (
          <>
            <section className="section">
              <Row>
                <Col>
                  <h2>{t('pages.modules.section.title.pointSources')}</h2>
                </Col>
              </Row>
              <Row>
                {(modules)
                  ? modules
                    .filter((m: Record<string, any>) => m.domain === 'Risikoquellen auf dem Hof')
                    .sort((a: Record<string, any>, b: Record<string, any>) => a.order - b.order)
                    .map((m: Record<string, any>) => {
                      const completed = (assessments)
                        ? assessments[m.id]?.submitted
                        : false;
                      // set moduleName & description to selected language
                      const moduleName = (i18next.language !== 'de') ? m[`title_${i18next.language}`] ?? m.title : m.title;
                      const description = (i18next.language !== 'de') ? m[`description_${i18next.language}`] ?? m.description : m.description;
                      return (
                        <ModuleThumbnail
                          title={moduleName}
                          id={m.id}
                          imgSrc={m.imgSrc}
                          description={description}
                          completed={completed || false}
                          key={moduleName}
                          status={m.status}
                        />
                      );
                    })
                  : null}
              </Row>
            </section>
            <section className="section">
              <Row>
                <Col>
                  <h2>{t('pages.modules.section.title.diffuseSources')}</h2>
                </Col>
              </Row>
              <Row>
                {(modules)
                  ? modules
                    .filter((m: Record<string, any>) => m.domain === 'Risikoquellen auf dem Feld')
                    .sort((a: Record<string, any>, b: Record<string, any>) => a.order - b.order)
                    .map((m: Record<string, any>) => {
                      const completed = (assessments)
                        ? assessments[m.id]?.submitted
                        : false;
                      // set moduleName & description to selected language
                      const moduleName = (i18next.language !== 'de') ? m[`title_${i18next.language}`] ?? m.title : m.title;
                      const description = (i18next.language !== 'de') ? m[`description_${i18next.language}`] ?? m.description : m.description;
                      return (
                        <ModuleThumbnail
                          title={moduleName}
                          id={m.id}
                          imgSrc={m.imgSrc}
                          description={description}
                          completed={completed || false}
                          key={moduleName}
                          status={m.status}
                        />
                      );
                    })
                  : null}
              </Row>
            </section>
          </>
        )}

    </>
  );
}
