import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

function ResetPasswordForm() {
  const { t } = useTranslation('common');
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const navigate = useNavigate();

  const forgotPassword = (email:string) => {
    /**
         * TODOS:
         * - Error handling
         * - Notification for user
         *
         */
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then((res) => {
        setEmailSent(true);
      }).catch((e) => {
        console.log(e);
      });
  };

  const {
    values,
    handleSubmit,
    submitCount,
    getFieldProps,
    setValues,
    touched,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .required('Required'),
    }),
    onSubmit: (values) => {
      forgotPassword(values.email);
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    },
  });

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <h1>{t('pages.resetPassword.title')}</h1>
      {(emailSent)
        ? (
          <Alert variant="success">
            {t('pages.resetPassword.notification.success')}
          </Alert>
        )
        : null}
      <Form.Group className="mb-3" controlId="email">
        <Form.Label>{t('form.labels.email')}</Form.Label>
        <Form.Text className="required-field">{touched.email && errors.email}</Form.Text>

        <Form.Control
          type="email"
          {...getFieldProps('email')}
        />
      </Form.Group>
      <Button type="submit">{t('pages.resetPassword.button.reset')}</Button>
    </Form>
  );
}

export default ResetPasswordForm;
