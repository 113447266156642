import React, { useEffect } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from './reducer/reduxHooks';
import validateInput from '../utils/validateInput';

interface CheckboxProps {
  questionId: string,
  question: string,
  order: number,
  subtitle?: string,
  moduleName: string,
  options: Record<string, any>,
  type: any,
  imageSrc?: string,
  currentAssessmentState: Record<string, any>,
  setButtonActive: any,
}

export default function Checkbox({
  questionId,
  question,
  order,
  subtitle,
  moduleName,
  options,
  type,
  imageSrc,
  currentAssessmentState,
  setButtonActive,
}: CheckboxProps) {
  const { i18n, t } = useTranslation('common');
  const dispatch = useAppDispatch();

  useEffect(() => {
    setButtonActive(
      validateInput(currentAssessmentState.assessment[questionId].answers, type),
    );
  }, [currentAssessmentState]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const selectedAnswer = options
      .filter((option: Record<string, any>) => option.id === e.currentTarget.id)[0];
    const payload = {
      id: questionId,
      order,
      customQuestionType: type,
      text: question,
      answers: {
        id: e.currentTarget.id,
        text: selectedAnswer.text,
        text_fr: selectedAnswer.text_fr,
        text_it: selectedAnswer.text_it,
        checked: e.currentTarget.checked,
      },
    };

    if (e.currentTarget.type === 'checkbox') {
      dispatch({ type: 'currentAssessment/updateCheckbox', payload, moduleName: moduleName.toLowerCase() });
    } else if (e.currentTarget.type === 'radio') {
      dispatch({ type: 'currentAssessment/updateRadio', payload, moduleName: moduleName.toLowerCase() });
    }
  };

  function createImageLabel(answerImageSrc: string) {
    return <img src={answerImageSrc} alt="" className="option-img" />;
  }

  function createCheckbox(
    text: string,
    answerId: string,
    showImage: boolean,
    answerImageSrc: string,
    index: number,
  ) {
    const [answer] = currentAssessmentState.assessment[questionId].answers
      .filter((answerObj: Record<string, any>) => answerObj.id === answerId);
    let checked;
    if (answer) {
      checked = answer.checked;
    } else {
      checked = false;
    }
    return (
      <>
        <Form.Check
          type={type}
          label={(showImage && answerImageSrc) ? createImageLabel(answerImageSrc) : text}
          name={(type === 'radio') ? questionId : text}
          checked={(currentAssessmentState.assessment[questionId]
            && currentAssessmentState.assessment[questionId].answers)
            ? checked
            : false}
          onChange={(e) => handleCheckboxChange(e)}
          key={index}
          id={answerId}
        />
        {/* {(showImage && answerImageSrc) ? createImageLabel(answerImageSrc) : null} */}
      </>
    );
  }

  function createCheckboxes(): Array<React.ReactNode> {
    return options.map((option: Record<string, any>, index: number) => {
      const {
        id,
        showImage,
      } = option;
      const text = (i18n.language !== 'de') ? option[`text_${i18n.language}`] ?? option.text : option.text;
      const answerImageSrc = (i18n.language !== 'de') ? option[`answerImageSrc_${i18n.language}`] ?? option.answerImageSrc : option.answerImageSrc;
      return createCheckbox(text, id, showImage, answerImageSrc, index);
    });
  }

  return (
    <>
      <FormGroup>
        <h3>{question}</h3>
        <h4><em>{subtitle}</em></h4>
        <div className="checkbox-hint">
          {(type === 'radio')
            ? <>{t('form.labels.onlyOneOption')}</>
            : <>{t('form.labels.multipleOptions')}</>}
        </div>
        {createCheckboxes()}
      </FormGroup>
      {(imageSrc)
        ? <img className="form-img" src={imageSrc} alt="Alttext" />
        : null}
    </>
  );
}

Checkbox.defaultProps = {
  subtitle: null,
  imageSrc: null,
};
