import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ResultLegend() {
  const { t } = useTranslation('common');
  return (
    <div className="summary-legend-wrapper">
      <div className="summary-legend-title">
        <h4>{t('result.legend.title')}</h4>
      </div>
      <div>
        <div className="ampel-wrapper">
          <div className="ampel-licht greenlight on" />
          <div className="legend-entry-title"><h5>{t('result.legend.good.title')}</h5></div>
        </div>
        <div>
          {t('result.legend.good.text')}
        </div>
        <div className="ampel-wrapper">
          <div className="ampel-licht orangelight on" />
          <div className="legend-entry-title"><h5>{t('result.legend.poor.title')}</h5></div>
        </div>
        <div>
          {t('result.legend.poor.text')}
        </div>
        <div className="ampel-wrapper">
          <div className="ampel-licht redlight on" />
          <div className="legend-entry-title"><h5>{t('result.legend.bad.title')}</h5></div>
        </div>
        <div>
          {t('result.legend.bad.text')}
        </div>
      </div>
    </div>
  );
}
