import { useFormik } from 'formik';
import React, { SetStateAction, useState } from 'react';
import { sendEmailVerification } from 'firebase/auth';
import { Alert, Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import signIn from '../auth/signIn';
import PageTitle from './layout/PageTitle';
import NotificationHandler from './notifications/NotificationHandler';

interface Props {
    setUser: SetStateAction<any>,
}

function getQueryFromUrl(query: string) {
  const { search } = useLocation();
  const name = new URLSearchParams(search).get(query);
  return name;
}

interface MsgComp {
  text: string,
  linkRoute?: string,
  onClick?: () => void,
  linkText: string,
}

function MessageComponent({
  text, linkRoute, onClick, linkText,
} : MsgComp) {
  const { t } = useTranslation('common');
  return (
    <>
      {t(text)}
      &nbsp;
      <Alert.Link
        href={linkRoute}
        onClick={onClick}
      >
        {t(linkText)}
      </Alert.Link>
    </>
  );
}

MessageComponent.defaultProps = {
  linkRoute: '',
  onClick: '',
};

export default function Login({ setUser }: Props) {
  const [loggedIn, setloggedIn] = useState<boolean|null>(null);
  const [userStatus, setUserStatus] = useState<Record<string, any>|null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const verifiedUser = getQueryFromUrl('verifiedUser');

  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(t('form.validation.email.notValid') ?? '')
        .required(t('form.validation.required') ?? ''),
      password: Yup.string()
        .required(t('form.validation.required') ?? ''),
    }),
    onSubmit: (values) => {
      signIn({ email: values.email, password: values.password })
        .then((res) => {
          if (res && res.statusCode === 400) {
            setUserStatus(res);
            setloggedIn(false);
          } else if (res && res.statusCode === 200) {
            setUserStatus(res);
            setUser(res.body);
            setloggedIn(true);

            // time to redirection
            setTimeout(() => {
              navigate('/');
            }, 2000);
          }
        });
    },
  });

  function handleNotification(loggedIn: boolean|null) {
    let msg;
    let variant;

    if (loggedIn !== null || verifiedUser === '0' || userStatus?.body === 'auth/user-not-verified') {
      if (loggedIn === true) {
        msg = t('notifications.success.login');
        variant = 'success';
      }
      if (userStatus?.body === 'auth/user-not-found') {
        msg = <MessageComponent text="notifications.failure.noAccount" linkRoute="/registrieren" linkText="notifications.failure.pleaseSignUp" />;
        variant = 'warning';
      }
      if (userStatus?.body === 'auth/wrong-password') {
        msg = t('notifications.failure.login');
        variant = 'danger';
      }
      if (verifiedUser === '0') {
        msg = t('notifications.warning.notVerified');
        variant = 'warning';
      }
      if (userStatus?.body === 'auth/user-not-verified') {
        msg = <MessageComponent text="notifications.warning.pleaseVerifyYourEmail" linkText="notifications.warning.sendEmailVerification" onClick={() => { sendEmailVerification(userStatus.user); }} />;
        variant = 'warning';
      }
      return (
        <NotificationHandler
          variant={variant}
          message={msg}
        />
      );
    }
    return null;
  }

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <PageTitle pageTitle="Login" />
      {handleNotification(loggedIn)}
      <Form.Group className="mb-3" controlId="email">
        <Form.Label>{t('form.labels.email')}</Form.Label>
        <Form.Text className="required-field">{touched.email && errors.email}</Form.Text>
        <Form.Control
          type="email"
          {...getFieldProps('email')}
          className={errors.email ? 'invalid-field-value' : ''}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="password">
        <Form.Label>{t('form.labels.password')}</Form.Label>
        <Form.Text className="required-field">{touched.password && errors.password}</Form.Text>
        <Form.Control
          type="password"
          {...getFieldProps('password')}
          className={errors.password ? 'invalid-field-value' : ''}
        />
      </Form.Group>
      <Link to="/reset-password">{t('login.links.passwordForgotten')}</Link>
      <div className="sct-form-button-nav">
        <Button type="submit">{t('form.buttons.signIn')}</Button>
      </div>
    </Form>
  );
}
