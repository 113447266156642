import React, { useEffect } from 'react';
import {
  Col, Form, FormGroup, Row,
} from 'react-bootstrap';
import validateInput from '../utils/validateInput';
import { useAppDispatch } from './reducer/reduxHooks';

interface InputFieldProps {
  questionId: string,
  question: string,
  questionType: string,
  order: number,
  moduleName: string,
  options: Record<string, any>,
  currentAssessmentState: {[key: string]: any},
  subtitle?: string,
  setButtonActive: any,
}

export default function InputField({
  questionId,
  question,
  questionType,
  order,
  subtitle,
  moduleName,
  options,
  currentAssessmentState,
  setButtonActive,
}: InputFieldProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    setButtonActive(
      validateInput(currentAssessmentState.assessment[questionId].answers, questionType),
    );
  }, [currentAssessmentState]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement | any>): void => {
    const [answer] = options
      .filter((option: Record<string, any>) => option.id === e.currentTarget.id);
    const payload = {
      id: questionId,
      order,
      customQuestionType: questionType,
      text: question,
      answers: {
        id: e.currentTarget.id,
        text: answer.text,
        text_fr: answer.text_fr,
        text_it: answer.text_it,
        value: e.currentTarget.value,
      },
    };
    dispatch({ type: 'currentAssessment/updateInput', payload, moduleName: moduleName.toLowerCase() });
  };

  function createInputField(
    answerId: string,
    inputType: string,
    index: number,
  ) {
    return (
      <Form.Group as={Row} className="mb-3">
        <Col sm={2}>
          <Form.Control
            as="input"
            type={inputType}
            name={questionId} // question id instead of option as there is no given option
            value={(currentAssessmentState.assessment[questionId]
              && currentAssessmentState.assessment[questionId].answers
                .filter((answer: Record<string, any>) => answer.id === answerId))
              ? currentAssessmentState.assessment[questionId].answers
                .filter((answer: Record<string, any>) => answer.id === answerId)[0].value
              : ''}
            onChange={(e) => handleCheckboxChange(e)}
            key={index}
            id={answerId}
          />
        </Col>
      </Form.Group>
    );
  }

  function createAnswers(): Array<React.ReactNode> {
    return options.map((option: Record<string, any>, index: number) => {
      const { id, inputType } = option;
      return createInputField(id, inputType, index);
    });
  }

  return (
    <FormGroup>
      <h3>{question}</h3>
      <h4><em>{subtitle}</em></h4>
      {createAnswers()}
    </FormGroup>
  );
}

InputField.defaultProps = {
  subtitle: null,
};
