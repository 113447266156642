/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import signUp from '../auth/signUp';
import PageTitle from './layout/PageTitle';
import NotificationHandler from './notifications/NotificationHandler';
import UserForm from './profile/UserForm';

interface Props {
    setUser: any
}

function SignUp({ setUser }: Props) {
  const [notification, SetNotification] = useState<Record<string, string>|null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  async function submitHandler(data: Record<string, any>): Promise<void> {
    const res = await signUp({
      email: data.email,
      password: data.password,
      userGroup: data.userGroup,
      name: data.name,
      lastName: data.lastName,
    });

    if (res?.statusCode === 201) {
      // setUser(res.body);
      SetNotification({ variant: 'success', message: t('notifications.success.signedUp') });
      // Time to redirection
      setTimeout(() => {
        navigate('/login?verifiedUser=0');
      }, 2000);
    } else if (res?.statusCode === 'auth/email-already-in-use') {
      // SetNotification({ variant: 'warning', message: "E-Mail-Adresse wird bereits verwendet.
      // <Alert.Link href='/login'>Bitte melden Sie sich an</Alert.Link>." });
      SetNotification({ variant: 'warning', message: t('notifications.failure.email.alreadyUsed') });
    } else {
      SetNotification({ variant: 'danger', message: t('notifications.failure.tryLater') });
    }
  }

  return (
    <>
      <PageTitle pageTitle={t('pages.signUp.title')} />
      <p>
        {t('pages.signUp.intro')}
      </p>
      <NotificationHandler variant={notification?.variant} message={notification?.message} />
      <UserForm submitHandler={submitHandler} buttonText={t('form.buttons.signUp')} passwordField emailField={{ disable: false }} />
    </>
  );
}

export default SignUp;
