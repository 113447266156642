/* eslint-disable no-return-assign */
import {
  Button, Container, Col, Navbar, Nav, NavDropdown,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import React, { SetStateAction } from 'react';
import switchLang from '../../utils/switchLang';
import { UserContext } from '../UserContext';
import logOut from '../../auth/logOut';

function Navigation({ setUser }: SetStateAction<any>) {
  const { t, i18n } = useTranslation('common');
  const signOut = () => {
    logOut();
    setUser(null);
    window.location.href = '/login';
  };

  return (
    <header>
      <UserContext.Consumer>
        {(context) => (
          <Navbar bg="light" expand="lg">
            <Container>
              <Col lg="auto">
                <Navbar.Brand href="/">{t('nav.brandName')}</Navbar.Brand>
              </Col>
              <Col>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="">
                    <Col>
                      <Nav.Link href="/">{t('nav.links.home')}</Nav.Link>
                      <Nav.Link href="/modules">{t('nav.links.modules')}</Nav.Link>
                      {(context.user) ? <Nav.Link href="/assessments">{t('nav.links.results')}</Nav.Link> : null}
                      {/* {(context?.user?.roles.includes('admin')) ? <Nav.Link href="/admin/fragen">{t('nav.links.admin')}</Nav.Link> : null} */}
                    </Col>
                    <Col lg="auto" className="account-nav">
                      {(context.user)
                        ? (
                          <div className="button-wrapper">
                            <Button onClick={() => window.location.href = '/profile'} variant="secondary" title={t('nav.links.account') ?? ''}>
                              <i className="bi bi-person-circle" />
                              {' '}
                              {t('nav.links.account')}
                            </Button>
                            <Button onClick={signOut} variant="outline-secondary" title={t('nav.links.signOut') ?? ''}>
                              <i className="bi bi-box-arrow-right" />
                              {' '}
                              {t('nav.links.signOut')}
                            </Button>
                          </div>
                        )
                        : (
                          <div className="button-wrapper">
                            <Button onClick={() => window.location.href = '/registrieren'} variant="outline-secondary" title={t('nav.links.signUp') ?? ''}>
                              <i className="bi bi-person-plus-fill" />
                              {' '}
                              {t('nav.links.signUp')}
                            </Button>
                            <Button onClick={() => window.location.href = '/login'} variant="secondary" title={t('nav.links.signIn') ?? ''}>
                              <i className="bi bi-box-arrow-in-right" />
                              {' '}
                              {t('nav.links.signIn')}
                            </Button>
                          </div>
                        )}
                    </Col>
                    <Col lg="auto">
                      <NavDropdown title={i18n.language} id="nav-dropdown">
                        <NavDropdown.Item onClick={() => switchLang('de')}>de</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => switchLang('fr')}>fr</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => switchLang('it')}>it</NavDropdown.Item>
                      </NavDropdown>
                    </Col>
                  </Nav>
                </Navbar.Collapse>
              </Col>
            </Container>
          </Navbar>
        ) }
      </UserContext.Consumer>
    </header>
  );
}

export default Navigation;
