export default function returnRenderClass(answer: Record<string, any>, checkbox?: boolean) {
  switch (answer.correct) {
    case true:
      if (checkbox) {
        return 'true highlighted bi bi-check-square-fill';
      }
      return 'true highlighted';
    default:
      if (answer.risk === 'rot') {
        if (checkbox) {
          return 'false highlighted bi bi-x-square-fill';
        }
        return 'false highlighted';
      }
      if (answer.risk === 'neutral') {
        if (checkbox) {
          return 'highlighted bi bi-check-square';
        }
        return 'highlighted';
      }
      if (checkbox) {
        return 'poor highlighted bi bi-x-square-fill';
      }
      return 'poor highlighted';
  }
}
