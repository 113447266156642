import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationHandler from '../notifications/NotificationHandler';
import UserForm from './UserForm';
import { dataService } from '../../services/DataService';

interface Props {
    user: Record<string, any>|null,
    setUser: (user: Record<string, any>) => void
}

export default function UpdateProfile({ user, setUser }: Props) {
  const [notification, setNotification] = useState<Record<string, any>|null>(null);
  const { t } = useTranslation('common');

  const handleSubmit = async (data: Record<string, any>): Promise<void> => {
    const res = await dataService.setItem('users', user?.uid, data);
    let feedback;

    if (res?.statusCode === 201) {
      feedback = {
        variant: 'success',
        message: t('pages.profile.modal.notifications.success'),
      };
      setUser(res.body);
    } else {
      feedback = {
        variant: 'danger',
        message: t('pages.profile.modal.notifications.failure'),
      };
    }

    setNotification(feedback);
  };

  return (
    <>
      <NotificationHandler variant={notification?.variant} message={notification?.message} />
      <UserForm formValues={user} buttonText={t('pages.profile.modal.button.save')} emailField={{ disabled: true }} passwordField={false} submitHandler={handleSubmit} />
    </>
  );
}
