import React, { useEffect, useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { dataService } from '../services/DataService';
import { useAppSelector } from './reducer/reduxHooks';
import TitleBar from './layout/PageTitle';

export default function QuestionList() {
  const modules = useAppSelector((state) => state.moduleState.modules);
  const [questions, setQuestions] = useState(Array);
  const [filteredQuestions, setFilteredQuestions] = useState(Array);

  useEffect(() => {
    dataService.getItems('questions').then((res) => {
      if (res.statusCode === 200) {
        setQuestions(res.body);
      }
    });
  }, []);

  function selectQuestions(moduleId: string) {
    const selection = questions.filter((question: any) => question.topic === moduleId);
    setFilteredQuestions(selection);
  }

  function renderConditions(conditions: Array<Record<string, any>>) {
    const preparedConditions: Array<any> = [];
    if (conditions.length > 1) {
      preparedConditions.push('Bedingungen:');
      preparedConditions.push(<br />);
    } else if (conditions.length > 0) {
      preparedConditions.push('Bedingung:');
      preparedConditions.push(<br />);
    }
    conditions.forEach((condition: Record<string, any>) => {
      preparedConditions.push(`${condition.dependsOnQuestion} => ${condition.dependsOnAnswer}`);
      preparedConditions.push(<br />);
    });
    return preparedConditions;
  }

  return (
    <>
      <TitleBar pageTitle="Alle Fragen" />
      <section className="section">
        <Row>
          <Col lg={3}>
            <Form>
              <Form.Group className="mb-3" controlId="moduleSelection">
                <Form.Select onChange={(e) => selectQuestions(e.target.value)}>
                  <option>Modulauswahl</option>
                  {modules.map((module: Record<string, any>) => (
                    <option value={module.id}>{module.title}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </section>
      <section className="section">
        <Row>
          <Col>
            <ul className="item-list no-list-style">
              {filteredQuestions.sort((a: any, b: any) => a.order - b.order).map((q: any) => {
                const {
                  id, question, type, topic, order, conditions,
                } = q;
                return (
                  <li className="list-item">
                    <div className="list-item-title">
                      <Link to={`/admin/frage/${id}`}>{question}</Link>
                    </div>
                    <div className="list-item-meta">
                      <span>
                        Modul:
                        {` ${topic}`}
                        <br />
                        Position:
                        {` ${order}`}
                        <br />
                        Typ:
                        {` ${type}`}
                        <br />
                        ID:
                        {` ${id}`}
                        <br />
                        {(conditions)
                          ? renderConditions(conditions)
                          : null}
                      </span>
                    </div>
                  </li>
                );
              })}
            </ul>
          </Col>
        </Row>
      </section>
    </>
  );
}
