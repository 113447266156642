import React from 'react';
import { useTranslation } from 'react-i18next';

export default function contentApplikationImFeld() {
  const { t } = useTranslation('common');
  return ({
    playlist: [
      {
        title: 'elearning.video.integratedPestManagementViticulture.text',
        id: 'elearning.application.video.integratedPestManagementViticulture.id',
      },
      {
        title: 'elearning.video.integratedPestManagementOrchard.text',
        id: 'elearning.video.integratedPestManagementOrchard.id',
      },
      {
        title: 'elearning.video.integratedPestManagementArableFarming.text',
        id: 'elearning.video.integratedPestManagementArableFarming.id',
      },
    ],
    docs: [
      <a href={t('elearning.doc.pufferstreifen.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.pufferstreifen.text')}
      </a>,
      <a href={t('elearning.doc.reduktionDriftAckerbau.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.reduktionDriftAckerbau.text')}
      </a>,
      <a href={t('elearning.doc.reduktionDriftObstbau.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.reduktionDriftObstbau.text')}
      </a>,
      <a href={t('elearning.doc.reduktionDriftWeinbau.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.reduktionDriftWeinbau.text')}
      </a>,
      <a href={t('elearning.doc.praeziseApplikationstechnik.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.praeziseApplikationstechnik.text')}
      </a>,
      <a href={t('elearning.doc.weisungRisikoreduktionBLW.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.weisungRisikoreduktionBLW')}
      </a>,
      <a href={t('elearning.doc.toppsEmpfehlungDrift.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.toppsEmpfehlungDrift.text')}
      </a>,
      <a href={t('elearning.doc.toppsBestPracticeRunOffErosion.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.toppsBestPracticeRunOffErosion')}
      </a>,
    ],
    links: [
      <a href={t('elearning.link.JKIOriginaltabellen.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.link.JKIOriginaltabellen.text')}
      </a>,
    ],
  });
}
