import React from 'react';
import { useTranslation } from 'react-i18next';
import renderList from '../../utils/renderList';
import YTPlaylist from '../video/YTPlaylist';
import legalBasis from './legalNotes';

export default function elearningTabContent(content: Record<string, any>) {
  const { t } = useTranslation('common');
  const {
    playlist, docs, links,
  } = content;
  const legalNotes = legalBasis();
  return ({
    tabs: {
      active: t('elearning.tab.title.videos'),
      tabList: [
        {
          eventKey: 'videos',
          tabTitle: t('elearning.tab.title.videos'),
          tabContent: <YTPlaylist pl={playlist} />,
        },
        {
          eventKey: 'docs',
          tabTitle: t('elearning.tab.title.documents'),
          tabContent: (docs) ? renderList(docs) : null,
        },
        {
          eventKey: 'links',
          tabTitle: t('elearning.tab.title.links'),
          tabContent: (links) ? renderList(links) : null,
        },
        {
          eventKey: 'legal',
          tabTitle: t('elearning.tab.title.legalBasis'),
          tabContent: (legalNotes) ? renderList(legalNotes) : null,
        },
      ],
    },
  });
}
