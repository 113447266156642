export default function validateInput(
  answers: Array<Record<string, any>>,
  customQuestionType: string,
) {
  switch (customQuestionType) {
    case 'radio':
      return !answers.some((answer: Record<string, any>) => answer.checked === true);
    case 'checkbox':
      return !answers.some((answer: Record<string, any>) => answer.checked === true);
    case 'select':
      return !answers.some((answer: Record<string, any>) => answer.checked === true);
    case 'inputField':
      return !answers.every((answer: Record<string, any>) => answer.value !== '');
    case 'computedQuestion':
      return !answers.every((answer: Record<string, any>) => answer.value !== '');
    case 'answerRanking':
      return !answers.every((answer: Record<string, any>) => answer.value !== '');
    default:
      return false;
  }
}
