import {
  getAuth, signInWithEmailAndPassword, setPersistence, browserSessionPersistence,
} from 'firebase/auth';
import { dataService } from '../services/DataService';

interface signInProps {
    email: string,
    password: string
}

export default async function signIn({
  email,
  password,
}: signInProps) {
  const auth = getAuth();
  await setPersistence(auth, browserSessionPersistence);

  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const res = await dataService.getItem('users', userCredential.user.uid);
    if (userCredential.user.emailVerified) {
      return {
        statusCode: res.statusCode,
        body: res.body,
      };
    }
    return {
      statusCode: 400,
      body: 'auth/user-not-verified',
      user: userCredential.user,
    };
  } catch (error: any) {
    return {
      statusCode: 400,
      body: error.code,
    };
  }
}
