import React from 'react';
import { useTranslation } from 'react-i18next';

export default function contentAbdrift() {
  const { t } = useTranslation('common');
  return (
    {
      playlist: [
        // no translation with t() here. Will be done in video component
        {
          id: 'elearning.video.understandDrift.id',
          title: 'elearning.video.understandDrift.text',
        },
        {
          id: 'elearning.video.driftMeasuresArableFarming.id',
          title: 'elearning.video.driftMeasuresArableFarming.text',
        },
        {
          id: 'elearning.video.driftMeasuresViticulture.id',
          title: 'elearning.video.driftMeasuresViticulture.text',
        },
        {
          id: 'elearning.video.driftMeasuresOrchards.id',
          title: 'elearning.video.driftMeasuresOrchards.text',
        },
      ],
      docs: [
        <a href={t('elearning.doc.reduktionDriftObstbau.link') ?? ''} target="_blank" rel="noreferrer">
          {t('elearning.doc.reduktionDriftObstbau.text')}
        </a>,
        <a href={t('elearning.doc.pufferstreifen.link') ?? ''} target="_blank" rel="noreferrer">
          {t('elearning.doc.pufferstreifen.text')}
        </a>,
        <a href={t('elearning.doc.reduktionDriftAckerbau.link') ?? ''} target="_blank" rel="noreferrer">
          {t('elearning.doc.reduktionDriftAckerbau.text')}
        </a>,
        <a href={t('elearning.doc.reduktionDriftWeinbau.link') ?? ''} target="_blank" rel="noreferrer">
          {t('elearning.doc.reduktionDriftWeinbau.text')}
        </a>,
        <a href={t('elearning.doc.weisungRisikoreduktionBLW.link') ?? ''} target="_blank" rel="noreferrer">
          {t('elearning.doc.weisungRisikoreduktionBLW.text')}
        </a>,
        <a href={t('elearning.doc.toppsEmpfehlungDrift.link') ?? ''} target="_blank" rel="noreferrer">
          {t('elearning.doc.toppsEmpfehlungDrift.text')}
        </a>,
      ],
      links: [
        <a href={t('elearning.link.toppsSprizenOptimierung.link') ?? ''} target="_blank" rel="noreferrer">
          {t('elearning.link.toppsSprizenOptimierung.text')}
        </a>,
        <a href={t('elearning.link.toppsDriftrisikoDiagnose') ?? ''} target="_blank" rel="noreferrer">
          {t('elearning.link.toppsDriftrisikoDiagnose.text')}
        </a>,
        <a href={t('elearning.link.JKIOriginaltabellen.link') ?? ''} target="_blank" rel="noreferrer">
          {t('elearning.link.JKIOriginaltabellen.text')}
        </a>,
      ],
    }
  );
}
