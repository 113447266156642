import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ResultDetailsLegend() {
  const { t } = useTranslation('common');
  return (
    <section className="section">
      <div className="summary-legend-wrapper">
        <div className="summary-legend-title">
          <h4>{t('result.legend.iconList.title')}</h4>
        </div>
        <ul className="no-list-style">
          <li>
            <i className="true highlighted bi bi-check-square-fill" />
            {' '}
            {t('result.legend.good.title')}
          </li>
          <li>
            <i className="poor highlighted bi bi-x-square-fill" />
            {' '}
            {t('result.legend.poor.title')}
          </li>
          <li>
            <i className="false highlighted bi bi-x-square-fill" />
            {' '}
            {t('result.legend.bad.title')}
          </li>
          <li>
            <i className="highlighted bi bi-check-square" />
            {' '}
            {t('result.legend.neutral')}
          </li>
          <li>
            <i className="true feedback highlighted bi bi-exclamation-square" />
            {' '}
            {t('result.legend.notSelected')}
          </li>
        </ul>
      </div>
    </section>
  );
}
