function compareValues(firstValue: number, operator: string, secondValue: number) {
  switch (operator) {
    case '<':
      return firstValue < secondValue;
    case '<=':
      return firstValue <= secondValue;
    case '>':
      return firstValue > secondValue;
    case '>=':
      return firstValue >= secondValue;
    default:
      return firstValue === secondValue;
  }
}

export default compareValues;
