import React from 'react';
import returnRenderClass from '../../utils/returnRenderClass';

interface Props {
  answers: Array<Record<string, any>>,
  answerTitle: string,
}

export default function RenderInputField({ answers, answerTitle }: Props) {
  return (
    <>
      {answers.sort((a, b) => a.position - b.position)
        .map((answer: Record<string, any>, ind: number) => (
          <>
            {(ind === 0) ? <h4 className="highlighted">{answerTitle}</h4> : null}
            <ul className="no-list-style">
              <li>
                <span className={returnRenderClass(answer)}>
                  {answer.value}
                </span>
              </li>
            </ul>
          </>
        ))}
    </>
  );
}
