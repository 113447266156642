import React, { ReactChild, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';

interface Props {
    modalTitel?: string,
    children: Array<ReactChild> | ReactChild,
    buttonText: string,
    fullscreen?: true | 'md-down' | 'sm-down' | 'lg-down' | 'xl-down' | 'xxl-down' | undefined,
    showPrintButton?: boolean,
}

export default function CustomModal({
  modalTitel,
  children,
  buttonText,
  fullscreen,
  showPrintButton,
}: Props) {
  const [show, setShow] = useState<boolean>(false);
  const { t } = useTranslation('common');

  function handleShow() {
    setShow(true);
  }

  return (
    <>
      <Button variant="primary" onClick={() => handleShow()}>{buttonText}</Button>
      <Modal show={show} fullscreen={fullscreen ?? 'md-down'} dialogClassName="modal-80w" onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitel}</Modal.Title>
          {(showPrintButton)
            ? <Button variant="primary" className="sct-btn light btn-print" onClick={() => window.print()}><i className="bi bi-printer" /></Button>
            : null}
        </Modal.Header>
        <Modal.Body>
          <>
            {(fullscreen === true)
              ? (
                <Button variant="primary" title={t('buttons.back') ?? ''} onClick={() => setShow(false)}>
                  <i className="bi bi-chevron-left" />
                  {t('buttons.back')}
                </Button>
              )
              : null}
            {children}
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}

CustomModal.defaultProps = {
  modalTitel: '',
  fullscreen: 'md-down',
  showPrintButton: false,
};
