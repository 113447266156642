import React, { useEffect, useState } from 'react';
import { Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IsLoading from '../IsLoading';
import ResultSummary from './ResultSummary';
import ResultDetails from './ResultDetails';
import { dataService } from '../../services/DataService';
import getCurrentUser from '../../auth/getCurrentUser';

interface ResultProps {
    module: Record<string, any>,
    collection: string
    showGoToElearning?: boolean,
    showModuleOverview?: boolean,
}

export default function Results({
  module,
  collection,
  showGoToElearning,
  showModuleOverview,
}: ResultProps) {
  const [processedAssessment, setProcessedAssessment] = useState<Record<string, any>>({});
  const [details, setDetails] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation('common');

  useEffect(() => {
    const user = getCurrentUser();
    setTimeout(() => {
      dataService.process(collection, user?.uid, module.id)
        .then((res) => {
          if (res.statusCode === 200) {
            if (Array.isArray(res.body)) {
              if (Object.keys(res.body[0]).includes(module.id)) {
                // if multiple retakes select the last one
                setProcessedAssessment(res.body[0][module.id]);
              } else {
                setProcessedAssessment(res.body[0]);
              }
            } else {
              setProcessedAssessment(res.body[module.id][res.body[module.id].length - 1]);
            }
            setIsLoading(false);
          }
        });
    }, 2000);
  }, []);

  useEffect(() => {
    // Observes the details state and scrolls into view the detailed results
    // and the summary respectively
    const detailedResults = document.getElementById('detailed-results');
    const resultSummary = document.getElementById('result-summary');
    if (detailedResults) {
      detailedResults.scrollIntoView();
    } else {
      resultSummary?.scrollIntoView();
    }
  }, [details]);

  const handleClick = () => {
    setDetails((prevState: boolean) => !prevState);
  };

  return (
    <div>
      {(isLoading)
        ? <IsLoading />
        : (
          <Col>
            <section id="result-summary" className="section result-summary">
              <ResultSummary
                summaryRiskRating={processedAssessment?.summaryRiskRating}
                moduleTitle={module.title}
              />
              <div className="button-wrapper">
                <Button onClick={handleClick}>
                  {details ? t('result.button.detailedResults.hide') : t('result.button.detailedResults.show')}
                </Button>
                {(processedAssessment?.summaryRiskRating.success !== 1.0
                  && showGoToElearning === true)
                  ? <Link to={`/modules/${module.id}#elearning`}><Button>{t('result.button.goToElearning')}</Button></Link>
                  : null}
                <Link to="/assessments"><Button>{t('result.button.allMyResults')}</Button></Link>
              </div>
            </section>
            <section className="section result-details">
              {(details)
                ? (
                  <ResultDetails
                    module={module}
                    assessment={processedAssessment}
                    showGoToElearning={showGoToElearning}
                    showModuleOverview={showModuleOverview}
                  />
                )
                : null}
            </section>
          </Col>
        )}
    </div>
  );
}

Results.defaultProps = {
  showGoToElearning: true,
  showModuleOverview: true,
};
