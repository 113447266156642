import { getAuth } from 'firebase/auth';
// import { initializeApp } from 'firebase/app';
// import { firebaseConfig } from '../firebase/firebase-config';

export default function getCurrentUser(): Record<string, any> | null {
  // const app = initializeApp(firebaseConfig);
  // const auth = getAuth(app);
  const auth = getAuth();
  const user = auth.currentUser;

  if (user !== null) {
    // The user object has basic properties such as display name, email, etc.
    return {
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      // The user's ID, unique to the Firebase project. Do NOT use
      // this value to authenticate with your backend server, if
      // you have one. Use User.getToken() instead.
      uid: user.uid,
    };
  }

  return null;
}
