/* eslint-disable class-methods-use-this */
import { httpService } from './HTTPService';

class DataService {
  getItem(collection: string, id: string | undefined) {
    return httpService.fetch('GET', `/.netlify/functions/api/${collection}/${id}`, undefined);
  }

  // async deleteItem(id: string) {
  //     return httpService.fetch('DELETE', `/notes/${id}`, undefined);
  // }

  getItems(collection: string) {
    return httpService.fetch('GET', `/.netlify/functions/api/${collection}`, undefined);
  }

  queryItems(collectionName: string, queryParams: string) {
    return httpService.fetch('GET', `/.netlify/functions/api/${collectionName}?${queryParams}`, undefined);
  }

  setItem(collection: string, doc: string|undefined, values: Record<string, any>) {
    return httpService.fetch('POST', `/.netlify/functions/api/${collection}/${doc}`, values);
  }

  updateItem(collection: string, doc: string|undefined, values: Record<string, any>) {
    return httpService.fetch('PUT', `/.netlify/functions/api/${collection}/${doc}/`, values);
  }

  processAssessment(uid: string|undefined, moduleName?: string) {
    if (moduleName) {
      return httpService.fetch('GET', `/.netlify/functions/processAssessment/${uid}/${moduleName}`, undefined);
    }
    return httpService.fetch('GET', `/.netlify/functions/processAssessment/${uid}`, undefined);
  }

  process(collection: string, uid: string|undefined, moduleName?: string) {
    if (moduleName) {
      return httpService.fetch('GET', `/.netlify/functions/process/${collection}/${uid}/${moduleName}`, undefined);
    }
    return httpService.fetch('GET', `/.netlify/functions/process/${collection}/${uid}`, undefined);
  }
}

export const dataService = new DataService();
