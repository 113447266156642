import React from 'react';
import { Col } from 'react-bootstrap';

interface Props {
    children: HTMLElement | React.ReactNode,
    className: string
}

export default function ModuleIntro({ children, className }: Props) {
  return (
    <Col>
      <section className={`section ${className}`}>
        {children}
      </section>
    </Col>
  );
}
