import React, { useState, useEffect } from 'react';
import { Button, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useAppDispatch, useAppSelector } from '../reducer/reduxHooks';
import { setInitialLessonState, retakesEnabled } from '../reducer/actions';
import Elearning from './Elearning';
import elearningContent from './elearningContent';
import Module from '../modules/Module';
import ModuleIntro from '../modules/ModuleIntro';
import SelbstcheckButtonBox from './SelbstcheckButtonBox';
import IsLoading from '../IsLoading';
import type { RootState } from '../store';

function selectModule(moduleId: string | undefined) {
  return (state: RootState) => state.moduleState.modules
    .filter((module: Record<string, any>) => module.id === moduleId);
}

function selectLesson(moduleId: string) {
  const dispatch = useAppDispatch();
  return (state: RootState) => {
    if (moduleId && state.lessonState.lessons[moduleId] === undefined) {
      // if lesson does not exist dispatch action to initiate lessonState
      dispatch(setInitialLessonState(moduleId));
    }
    return state.lessonState.lessons[moduleId];
  };
}

export default function Lesson() {
  const { t } = useTranslation('common');
  const { moduleId } = useParams();
  const dispatch = useAppDispatch();
  const assessment = (moduleId) ? useAppSelector(selectLesson(moduleId)) ?? null : null;
  const assessmentSubmitted = assessment?.submitted ?? false;
  const [module] = useAppSelector(selectModule(moduleId));
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const content: Record<string, any> = elearningContent;
  // set moduleName & description to selected language
  const moduleDescription = (i18next.language !== 'de') ? module[`description_${i18next.language}`] ?? module.description : module.description;

  useEffect(() => {
    if (module) {
      setIsLoading(false);
    }
    if (assessmentSubmitted) {
      // Set redux store state retakes to true
      const action = {
        type: '',
        payload: {
          retakesEnabled: true,
          moduleId,
        },
      };
      dispatch(retakesEnabled(action));
      setTimeout(() => {
        const section = document.getElementById(window.location.hash.replace('#', ''));
        section?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  }, []);

  return (
    <Module
      moduleImgWrapperClassName="module-img-wrapper"
      moduleImgClassName="module-img"
      module={module}
    >
      {(isLoading)
        ? <IsLoading />
        : (
          <>
            <Row>
              <ModuleIntro className="sct-modul-intro">
                <p>
                  {moduleDescription}
                </p>
              </ModuleIntro>
            </Row>
            <Row>
              <SelbstcheckButtonBox
                module={module}
                imageSrc="/assets/img/SCT-icon.png"
                assessmentSubmitted={assessmentSubmitted}
              />
            </Row>
            <Row>
              {(assessmentSubmitted)
                ? (
                  <Elearning
                    moduleContent={(module) ? content[`${moduleId}`] : null}
                  />
                )
                : null}
            </Row>
            <div className="button-wrapper">
              <Link to="/modules">
                <Button variant="primary">{t('buttons.moduleOverview')}</Button>
              </Link>
              <Link to="/assessments">
                <Button variant="primary">{t('buttons.allMyResults')}</Button>
              </Link>
            </div>
          </>
        )}
    </Module>
  );
}
