import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import getCurrentUser from '../../auth/getCurrentUser';
import {
  modulesLoaded,
  questionsLoaded,
  assessmentsLoaded,
  retakesLoaded,
} from './actions';
import { dataService } from '../../services/DataService';

export async function fetchModules(dispatch: any) {
  const response = await dataService.getItems('modules');
  dispatch(modulesLoaded(response.body));
}

export function fetchQuestionsByTopic(moduleId: string) {
  return async function fetchQuestions(dispatch: ThunkDispatch<Promise<void>, void, AnyAction>) {
    const response = await dataService.queryItems('questions', `topic=${moduleId}`);
    const payload = { questions: response.body, moduleId };
    const type = '';
    dispatch(questionsLoaded({ payload, type }));
  };
}

export async function fetchRetakes(dispatch: ThunkDispatch<Promise<void>, void, AnyAction>) {
  const user = getCurrentUser();
  if (user) {
    const response = await dataService.getItem('retakes', user.uid);
    let payload;
    if (response.statusCode === 200) {
      payload = response.body;
    } else {
      payload = [];
    }
    dispatch(retakesLoaded(payload));
  }
}

export async function fetchLessons(dispatch: ThunkDispatch<Promise<void>, void, AnyAction>) {
  const user = getCurrentUser();
  if (user) {
    const response = await dataService.getItem('assessments', user.uid);
    let data;
    if (response.statusCode === 200) {
      data = response.body;
    } else {
      data = {};
    }
    dispatch(assessmentsLoaded(data));
  }
}

export function submitAssessment(payload: Record<string, any>) {
  return async function setAssessment(dispatch: ThunkDispatch<Promise<void>, void, AnyAction>) {
    const user = getCurrentUser();
    if (user) {
      // write current state (assessment data) to firestore
      const response = await dataService.updateItem('assessments', user?.uid, payload);
      if ([201, 200].includes(response.statusCode)) {
        // update store
        dispatch(fetchLessons);
      }
    }
  };
}

export function submitRetake(payload: Record<string, any>) {
  return async function setRetake(dispatch: ThunkDispatch<Promise<void>, void, AnyAction>) {
    const user = getCurrentUser();
    if (user) {
      // write current state (assessment data) to firestore
      const response = await dataService.updateItem('retakes', user?.uid, payload);
      if (response.statusCode === 200) {
        // update store
        dispatch(fetchRetakes);
      }
    }
  };
}
