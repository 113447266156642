import React, { ReactChild } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from '../UserContext';
import isInArray from '../../utils/isInArray';

interface Props {
    children: ReactChild,
    roles?: Array<string>
}

function RestrictedRoute({
  children,
  roles,
}: Props) {
  const location = useLocation();
  return (
    <UserContext.Consumer>
      {(context) => {
        if (context.user === null) {
          // not logged in so redirect to login page with the return url
          return <Navigate to="/login" state={location} />;
        }
        // role not authorised so redirect to home page
        if (roles && !isInArray(context?.user?.roles, roles)) {
          return <Navigate to="/" />;
        }
        // authorised so return component
        return children;
      }}
    </UserContext.Consumer>
  );
}

RestrictedRoute.defaultProps = {
  roles: null,
};

export default RestrictedRoute;
