import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

export default function About() {
  const { t } = useTranslation('common');
  return (
    <Row>
      <Col>
        <section className="module-title-section">
          <h1>{t('pages.about.title')}</h1>
        </section>
        <section className="section">
          <Trans i18nKey="pages.about.section.editor" ns="common">
            <h2>Herausgeberin  (verantwortlich für Konzept und Inhalte)</h2>
            <p>
              Plattform Pflanzenschutzmittel und Gewässer PPG
              <br />
              Hochschule für Agrar-, Forst- und Lebensmittelwissenschaften HAFL
              <br />
              Länggasse 85
              <br />
              CH-3052 Zollikofen
            </p>
          </Trans>
          <p>
            <a href={t('pages.about.websitePpg.link') ?? 'https://pflanzenschutzmittel-und-gewaesser.ch/'} target="_blank" rel="noreferrer">
              {t('pages.about.websitePpg.text')}
            </a>
            <br />
            sct-info [at] agridea.ch
          </p>
        </section>
        <section className="section">
          <Trans i18nKey="" ns="common">
            <h2>Auftraggeber</h2>
            <p>
              Bundesamt für Umwelt (BAFU), Abt. Wasser, CH-3003 Bern
              <br />
              Das BAFU ist ein Amt des Eidg. Departements für Umwelt,
              Verkehr, Energie und Kommunikation (UVEK).
            </p>
          </Trans>
        </section>
        <section className="section">
          <Trans i18nKey="pages.about.section.support" ns="common">
            <h2>Technische Umsetzung und Support</h2>
            <p>
              AGRIDEA
              <br />
              Eschikon 28
              <br />
              CH-8315 Lindau
            </p>
          </Trans>
          <p>
            <a href={t('pages.about.websiteAgridea.link') ?? 'https://www.agridea.ch/'} target="_blank" rel="noreferrer">
              {t('pages.about.websiteAgridea.text')}
            </a>
          </p>
        </section>
        <section className="section">
          <p>
            <a href="/contact"><Button variant="primary">{t('buttons.contactForm')}</Button></a>
          </p>
        </section>
      </Col>
    </Row>
  );
}
