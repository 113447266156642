import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default function selectUserFormValidationScheme(passwordField: boolean) {
  const { t } = useTranslation('common');
  let scheme;
  const baseScheme = {
    name: Yup.string()
      .required(t('form.validation.required') ?? 'required'),
    lastName: Yup.string()
      .required(t('form.validation.required') ?? 'required'),
    email: Yup.string()
      .email(t('form.validation.email.notValid') ?? 'email not valid')
      .required(t('form.validation.required') ?? 'required'),
    userGroup: Yup.string()
      .required(t('form.validation.required') ?? 'required'),
  };

  if (passwordField) {
    scheme = Object.assign(baseScheme, {
      password: Yup.string()
        .min(8, t('form.validation.password.length') ?? 'min. 8 characters')
        .required(t('form.validation.required') ?? 'required'),
    });
  } else {
    scheme = baseScheme;
  }

  return Yup.object().shape(scheme);
}
