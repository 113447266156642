import React from 'react';
import {
  Col, Form, Row, FloatingLabel,
} from 'react-bootstrap';

interface ConditionProps {
  index: number
  formik: Record<string, any>,
  questions: Array<Record<string, any>>,
  key: number
}

export default function ConditionFormField({
  index, formik, questions, key,
}: ConditionProps) {
  const ind = index;

  function getQuestionType(questions: Array<Record<string, any>>) {
    return questions.filter((question) => question.id === formik.values.conditions[ind].dependsOnQuestion)[0].type;
  }

  function renderConditionFields() {
    if (formik.values.conditions[ind].dependsOnQuestion && questions.length > 0) {
      // if condition should match user input
      if (['computedQuestion', 'inputField'].includes(getQuestionType(questions))) {
        return (
          <>
            <Col>
              <Form.Group controlId={`conditions[${ind}].dependsOnAnswer`}>
                <Form.Label>Antwort</Form.Label>
                <Form.Select
                  aria-label="Antwort"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.conditions[ind].dependsOnAnswer}
                >
                  <option>Bitte wählen:</option>
                  {(formik.values.conditions[ind].dependsOnQuestion !== '')
                    ? questions
                      .filter((question) => question.id === formik.values.conditions[ind].dependsOnQuestion)[0]?.options
                      .map((answer: Record<string, string>) => (
                        <option value={answer.id}>{answer.text}</option>
                      ))
                    : null}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group controlId={`conditions[${ind}].thresholdOperator`}>
                <Form.Label>Operator</Form.Label>
                <Form.Select
                  aria-label="Operator"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.conditions[ind].thresholdOperator}
                >
                  <option>Bitte wählen:</option>
                  <option value=">">&gt;</option>
                  <option value=">=">&gt;=</option>
                  <option value="=">=</option>
                  <option value="<=">&lt;=</option>
                  <option value="<">&lt;</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId={`conditions[${ind}].dependsOnAnswerValue`}>
                <Form.Label>Wert</Form.Label>
                <Form.Control
                  aria-label="Wert"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.conditions[ind].dependsOnAnswerValue}
                />
              </Form.Group>
            </Col>
          </>
        );
      }

      // if condition should match specific answer from multiple or single choice question
      return (
        <Col>
          <Form.Group controlId={`conditions[${ind}].dependsOnAnswer`}>
            <Form.Select
              aria-label="Wenn Antwort"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.conditions[ind].dependsOnAnswer}
            >
              <option>Wenn Antwort:</option>
              {(formik.values.conditions[ind].dependsOnQuestion !== '')
                ? questions
                  .filter((question) => question.id === formik.values.conditions[ind].dependsOnQuestion)[0]?.options
                  .map((answer: Record<string, string>) => (
                    <option value={answer.id}>{answer.text}</option>
                  ))
                : null}
            </Form.Select>
          </Form.Group>
        </Col>
      );
    }
    return null;
  }

  return (
    <Row className="mb-3">
      {(ind > 0)
        ? (
          <Col md={2}>
            <Form.Group controlId={`conditions[${ind}].operator`}>
              <Form.Select
                aria-label="Operator"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.conditions[ind].operator}
              >
                <option>Operator:</option>
                <option value="and">AND</option>
                <option value="or">OR</option>
              </Form.Select>
            </Form.Group>
          </Col>
        )
        : null}
      <Col>
        <Form.Group controlId={`conditions[${ind}].dependsOnQuestion`}>
          <Form.Select
            aria-label="Bedingt Frage"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.conditions[ind].dependsOnQuestion}
          >
            <option>Bedingt Frage:</option>
            {questions.map((question) => (
              <option value={question.id}>{question.id}</option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
      {renderConditionFields()}
    </Row>
  );
}
