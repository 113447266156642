import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  answers: Array<Record<string, any>>
}

export default function RenderFeedback({ answers }: Props) {
  const { t } = useTranslation('common');
  const { questionType } = answers[0];
  const correctAnswers = answers.filter((answer: Record<string, any>) => answer.correct === true);
  const selectedCorrectAnswers = correctAnswers
    .filter((answer: Record<string, any>) => answer.checked === answer.correct);

  let falseAnswers;
  if (questionType === 'answerRanking') {
    falseAnswers = answers
      .filter((answer: Record<string, any>) => (answer.position !== answer.value));
  } else if (['computedQuestion'].includes(questionType)) {
    falseAnswers = answers.filter((answer: Record<string, any>) => (answer.correct === false));
  } else {
    falseAnswers = answers
      .filter((answer: Record<string, any>) => (answer.checked !== answer.correct));
  }

  const goodPracticeFeedback = Array.from(new Set(selectedCorrectAnswers
    .map((answer: Record<string, any>) => ((i18next.language !== 'de')
      ? answer[`feedback_${i18next.language}`] || answer.feedback
      : answer.feedback))));

  if (questionType === 'radio' && correctAnswers.length > 1 && selectedCorrectAnswers.length >= 1) {
    return (
      <span className="true highlighted">{goodPracticeFeedback}</span>
    );
  }

  // if no positive feedback is in the answers (checkbox, answerRanking, computedQuestion)
  // return standard phrase
  if (['checkbox'].includes(questionType) && falseAnswers.length === 0) {
    return (
      <span className="true highlighted">{t('result.feedback.goodPractice')}</span>
    );
  }
  if (['answerRanking'].includes(questionType) && falseAnswers.length === 0) {
    return (
      <span className="true highlighted">{t('result.feedback.correctAnswer')}</span>
    );
  }
  if (['computedQuestion'].includes(questionType) && falseAnswers.length === 0) {
    return (
      <span className="true highlighted">{t('result.feedback.sufficentWaterTankCapacity')}</span>
    );
  }
  // else return positive feedback from answer
  if (falseAnswers.length === 0) {
    return (
      <span className="true highlighted">{goodPracticeFeedback}</span>
    );
  }

  let feedbacks;
  if (['computedQuestion', 'inputField', 'answerRanking'].includes(questionType)) {
    feedbacks = Array.from(new Set(falseAnswers
      .filter((answer: any) => answer.correct === false)
      .map((answer: any) => ((i18next.language !== 'de')
        ? answer[`feedback_${i18next.language}`] || answer.feedback
        : answer.feedback))));
  } else if (questionType === 'radio') {
    const checkedFeedbacks = Array.from(new Set(falseAnswers
      .filter((answer: any) => answer.checked === true)
      .map((answer: any) => ((i18next.language !== 'de')
        ? answer[`feedback_${i18next.language}`] || answer.feedback
        : answer.feedback))));
    if (checkedFeedbacks.length === 0) {
      feedbacks = Array.from(new Set(answers
        .filter((answer: Record<string, any>) => answer.correct === false)
        .map((answer: Record<string, any>) => ((i18next.language !== 'de')
          ? answer[`feedback_${i18next.language}`] || answer.feedback
          : answer.feedback))));
    } else {
      feedbacks = checkedFeedbacks;
    }
  } else {
    feedbacks = Array
      .from(new Set(falseAnswers.filter((answer: any) => answer.correct === true)
        .map((answer: any) => {
          const feedback = (i18next.language !== 'de')
            ? answer[`feedback_${i18next.language}`] || answer.feedback
            : answer.feedback;
          return feedback;
        })));
  }

  if (feedbacks.length > 0 && feedbacks[0] !== undefined) { // render feedback only if available
    const feedbackTitle = (feedbacks.length < 2) ? t('result.details.feedbackTitle') : t('result.details.feedbackTitle_plural');
    return (
      <>
        {feedbacks.map((feedback: string, ind: number) => (
          <>
            {(ind === 0) ? <h4 className="false highlighted feedback-title">{feedbackTitle}</h4> : null}
            <p>{feedback}</p>
          </>
        ))}
      </>
    );
  }
  return null;
}
