import { createContext } from 'react';

interface UserContextValue {
    user: Record<string, any>|null,
    setUser: (user: Record<string, any>) => void;
  }

export const UserContext = createContext<UserContextValue>({
  user: null,
  setUser: () => {},
});
