/* eslint-disable react/no-array-index-key */
import { ProgressBar } from 'react-bootstrap';
import React, { useRef, useState } from 'react';
import RiskBarLegend from './RiskBarLegend';

interface Props {
    summaryRiskRating: Record<string, number>,
}

export default function RiskBar({ summaryRiskRating }: Props) {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState<any>();
  const ref = useRef(null);

  const handleMouseOver = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };
  const legend = RiskBarLegend();

  return (
    <div className="summary-risk-bar" ref={ref}>
      <ProgressBar>
        {legend.map((entry: Record<string, string>, index: number) => (
          <ProgressBar
            id={entry.variant}
            onMouseOver={handleMouseOver}
            variant={entry.variant}
            now={summaryRiskRating[entry.variant] * 100}
            label={`${Math.round(summaryRiskRating[entry.variant] * 100)}%`}
            key={index}
          />
        ))}
      </ProgressBar>
    </div>
  );
}
