/* eslint-disable react/no-array-index-key */
/**
 * Renders HTML elements or text as a unordered list
 */

import React, { ReactElement } from 'react';

export default function renderList(arr: Array<ReactElement>) {
  return (
    <ul>
      {arr.map((item, idx) => <li key={idx}>{item}</li>)}
    </ul>
  );
}
