import { connect } from 'react-redux';
import QuestionForm from './QuestionForm';

interface State {
    [key: string]: any
}

const mapStateToProps = (state: State) => ({
  questions: state.questions,
});

const QuestionFormContainer = connect(mapStateToProps)(QuestionForm);

export default QuestionFormContainer;
