import { useTranslation } from 'react-i18next';

const RiskBarLegend = () => {
  const { t } = useTranslation('common');
  return ([
    {
      variant: 'success',
      title: t('result.riskbar.legend.good.title'),
      description: t('result.riskbar.legend.good.text'),
    },
    {
      variant: 'warning',
      title: t('result.riskbar.legend.poor.title'),
      description: t('result.riskbar.legend.poor.text'),
    },
    {
      variant: 'danger',
      title: t('result.riskbar.legend.bad.title'),
      description: t('result.riskbar.legend.bad.text'),
    },
  ]);
};

export default RiskBarLegend;
