import React from 'react';
import { Trans } from 'react-i18next';

interface Props {
    module: string
}
// const { t, i18n } = useTranslation('common');

function CommentaryGoodPractice() {
  return (
    <Trans i18nKey="result.summary.comment.good" ns="common">
      <p>
        Ihr Umgang mit den Pflanzenschutzmitteln in Bezug auf den
        Gewässerschutz ist vorbildlich!&nbsp;
        <strong>Ausgezeichnet, weiter so!</strong>
      </p>
      <p>Eine detaillierte Analyse über diese Bereiche finden sie im Anschluss.</p>
    </Trans>
  );
}

function CommentaryPoorPractice({ module }: Props) {
  return (
    <Trans i18nKey="result.summary.comment.poor" ns="common">
      <p>
        Es konnten keine nennenswerten Mängel oder potentielle Risikoquellen
        im Umgang mit Pflanzenschutzmitteln, für die bestehende Infrastruktur
        oder der angewandten Technik festgestellt werden, die ein akutes Risiko
        für Gewässer darstellen In einigen Teilbereichen zeigte sich jedoch, dass sie beim Thema «
        {{ module }}
        » zwar gut über gewässerschutz-relevanten Umgang mit Pflanzenschutzmitteln
        informiert und geübt sind, es aber noch&nbsp;
        <strong>Verbesserungspotential</strong>
        &nbsp;gibt.
      </p>
      <p>
        Wir empfehlen Ihnen, sich&nbsp;
        <strong>weiterführende Informationen</strong>
        &nbsp;zum Thema «
        {{ module }}
        » auf unserer Lernplattform anzuschauen.
      </p>
      <p>Eine detaillierte Analyse über diese Bereiche finden sie im Anschluss.</p>
    </Trans>
  );
}

function CommentaryBadPractice({ module }: Props) {
  return (
    <Trans i18nKey="result.summary.comment.bad" ns="common">
      <p>
        Sie zeigen in verschiedenen Bereichen beim Thema «
        {{ module }}
        » einen guten Umgang mit Pflanzenschutzmitteln in Bezug auf den Gewässerschutz.
        Teilweise wurden jedoch&nbsp;
        <strong>deutliche gewässerschutzrelevante Mängel oder Risikoquellen</strong>
        &nbsp;festgestellt, bei denen es noch Verbesserungspotential gibt.
      </p>
      <p>
        Es wird&nbsp;
        <strong>dringend empfohlen</strong>
        , zum Thema «
        {{ module }}
        » eine&nbsp;
        <strong>betriebsspezifische Beratung</strong>
        &nbsp;zu vereinbaren und/oder sich auf unserer Lernplattform&nbsp;
        <strong>weiterführende Informationen</strong>
        &nbsp;zu besorgen.
      </p>
      <p>Eine detaillierte Aanalyse über diese Bereiche finden sie im Anschluss.</p>
    </Trans>
  );
}

export { CommentaryGoodPractice, CommentaryPoorPractice, CommentaryBadPractice };
