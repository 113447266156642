import React from 'react';
import { useTranslation } from 'react-i18next';

export default function contentLagerung() {
  const { t } = useTranslation('common');
  return ({
    playlist: [
      {
        id: 'elearning.video.storageAndPreparation.id',
        title: 'elearning.video.storageAndPreparation.text',
      },
      {
        id: 'elearning.video.treatmentPlanning.id',
        title: 'elearning.video.treatmentPlanning.text',
      },
      {
        id: 'elearning.video.containerDisposal.id',
        title: 'elearning.video.containerDisposal.text',
      },
    ],
    docs: [
      <a href={t('elearning.doc.kontrolllisteLagerung.link') ?? ''} target="_blank" rel="noreferrer">{t('elearning.doc.kontrolllisteLagerung.text')}</a>,
      <a href={t('elearning.doc.checklistePMSGewaesser.link') ?? ''} target="_blank" rel="noreferrer">{t('elearning.doc.checklistePMSGewaesser.text')}</a>,
      <a href={t('elearning.doc.korrekteLagerungAG.link') ?? ''} target="_blank" rel="noreferrer">{t('elearning.doc.korrekteLagerungAG.text')}</a>,
      <a href={t('elearning.doc.fachstelleLagerungSO.link') ?? ''} target="_blank" rel="noreferrer">{t('elearning.doc.fachstelleLagerungSO.text')}</a>,
      <a href={t('elearning.doc.fachstelleLagerungTG.link') ?? ''} target="_blank" rel="noreferrer">{t('elearning.doc.fachstelleLagerungTG.text')}</a>,
      <a href={t('elearning.doc.fachstelleLagerungZH.link') ?? ''} target="_blank" rel="noreferrer">{t('elearning.doc.fachstelleLagerungZH.text')}</a>,
      <a href={t('elearning.doc.SSALeitfadenLagerung.link') ?? ''} target="_blank" rel="noreferrer">{t('elearning.doc.SSALeitfadenLagerung.text')}</a>,
      <a href={t('elearning.doc.SSALeitfadenLagerungBeilage1.link') ?? ''} target="_blank" rel="noreferrer">{t('elearning.doc.SSALeitfadenLagerungBeilage1.text')}</a>,
      <a href={t('elearning.doc.SSALeitfadenLagerungBeilage2.link') ?? ''} target="_blank" rel="noreferrer">{t('elearning.doc.SSALeitfadenLagerungBeilage2.text')}</a>,
      <a href={t('elearning.doc.swissGAPNotfallplan.link') ?? ''} target="_blank" rel="noreferrer">{t('elearning.doc.swissGAPNotfallplan.text')}</a>,
      <a href={t('elearning.doc.swissGAPWarnhinweis.link') ?? ''} target="_blank" rel="noreferrer">{t('elearning.doc.swissGAPWarnhinweis.text')}</a>,
      <a href={t('elearning.doc.vollzugshilfeUmweltschutz.link') ?? ''} target="_blank" rel="noreferrer">{t('elearning.doc.vollzugshilfeUmweltschutz.text')}</a>,
    ],
    links: [],
  });
}
