/* eslint-disable react/jsx-no-constructed-context-values */
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { firebaseConfig } from './firebase/firebase-config';
import './scss/App.scss';
import './scss/custom.scss';
import { UserContext } from './components/UserContext';
import RestrictedRoute from './components/routes/RestrictedRoute';
import Layout from './components/layout/Layout';
import QuestionFormContainer from './components/backend/QuestionFormContainer';
import Login from './components/Login';
import QuestionList from './components/QuestionList';
import Modules from './components/modules/Modules';
import Profile from './components/profile/Profile';
import SignUp from './components/SignUp';
import Home from './components/Home';
import { fetchLessons } from './components/reducer/thunks';
import IsLoading from './components/IsLoading';
import ResetPasswordForm from './components/ResetPasswordForm';
import { dataService } from './services/DataService';
import AssessmentOverview from './components/assessement/AssessementOverview';
import Lesson from './components/lessons/Lesson';
import ModuleAssessment from './components/lessons/ModuleAssessment';
import { useAppDispatch } from './components/reducer/reduxHooks';
import About from './components/About';
import ContactForm from './components/Contactform';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const dispatch = useAppDispatch();
  const [user, setUser] = useState<Record<string, any>|null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // fetch user assessement data
        dispatch<any>(fetchLessons);
        setTimeout(() => {
          dataService.getItem('users', user?.uid)
            .then((res) => {
              if (res.statusCode === 200) {
                setUser(res.body);
              } else {
                setUser(null);
              }
              setIsLoading(false);
            });
        }, 500);
      } else {
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <div className="App">
      {(isLoading)
        ? <IsLoading />
        : (
          <UserContext.Provider value={{ user, setUser }}>
            <Layout setUser={setUser}>
              <BrowserRouter>
                <Routes>
                  <Route path="/modules" element={<Modules />} />
                  <Route path="/impressum" element={<About />} />
                  <Route path="/contact" element={<ContactForm />} />
                  <Route path="/nutzungsbedingungen" element={<PrivacyPolicy />} />
                  <Route
                    path="/modules/:moduleId"
                    element={(
                      <RestrictedRoute>
                        <Lesson />
                      </RestrictedRoute>
                    )}
                  />
                  <Route
                    path="/modules/:moduleId/selbstcheck"
                    element={(
                      <RestrictedRoute>
                        <ModuleAssessment />
                      </RestrictedRoute>
                    )}
                  />
                  <Route
                    path="/profile"
                    element={(
                      <RestrictedRoute>
                        <Profile />
                      </RestrictedRoute>
                    )}
                  />
                  <Route
                    path="/assessments"
                    element={(
                      <RestrictedRoute>
                        <AssessmentOverview />
                      </RestrictedRoute>
                    )}
                  />
                  <Route path="/registrieren" element={<SignUp setUser={setUser} />} />
                  <Route path="/reset-password" element={<ResetPasswordForm />} />
                  <Route path="/login" element={<Login setUser={setUser} />} />
                  <Route
                    path="/admin/frage/"
                    element={(
                      <RestrictedRoute roles={['admin']}>
                        <QuestionFormContainer />
                      </RestrictedRoute>
                    )}
                  />
                  <Route
                    path="/admin/frage/:id"
                    element={(
                      <RestrictedRoute roles={['admin']}>
                        <QuestionFormContainer />
                      </RestrictedRoute>
                    )}
                  />
                  <Route
                    path="/admin/fragen"
                    element={(
                      <RestrictedRoute roles={['admin']}>
                        <QuestionList />
                      </RestrictedRoute>
                    )}
                  />
                  <Route path="/" element={<Home />} />
                </Routes>
              </BrowserRouter>
            </Layout>
          </UserContext.Provider>
        )}
    </div>
  );
}

export default App;
