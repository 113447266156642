import { Dispatch, AnyAction } from '@reduxjs/toolkit';
import { assessmentPrepared } from '../components/reducer/actions';

export default function prepareCurrentAssessment(
  dispatch: Dispatch<AnyAction>,
  moduleId: string,
  questions: Array<Record<string, any>>,
) {
  const preparedAssessment: Record<string, any> = {
    assessment: {},
    uid: null,
    submitted: false,
    timestamp: null,
  };
  questions.forEach((question: Record<string, any>) => {
    const answers: Array<Record<string, any>> = [];
    // const { question_de, question_fr, question_it } = question;

    // set all checkbox and radio answers to false
    question.options.forEach((option: Record<string, string>) => {
      const answer: Record<string, any> = {};
      answer.text = option.text;
      answer.text_fr = option.text_fr;
      answer.text_it = option.text_it;
      answer.id = option.id;
      // further question types needs implementation here
      if (['checkbox', 'radio', 'select'].includes(question.type)) {
        answer.checked = false;
      } else {
        // default for all question types that have
        // no predefined answers
        answer.value = '';
      }
      answers.push(answer);
    });

    preparedAssessment.assessment[question.id] = {
      text: question.question,
      text_fr: question.question_fr,
      text_it: question.question_it,
      order: question.order,
      customQuestionType: question.type,
      answers, // return empty object by default
    };
  });
  dispatch(assessmentPrepared({ moduleId, payload: preparedAssessment }));
}
