import { getAuth, signOut } from 'firebase/auth';

const logOut = (): void => {
  const auth = getAuth();

  signOut(auth).then(() => {
    // do nothing
  }).catch((error) => {
    // eslint-disable-next-line no-console
    console.log(error);
  });
};

export default logOut;
