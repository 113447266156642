import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RenderResults from './RenderResults';
import ResultDetailsLegend from './ResultDetailsLegend';
import ResultSummary from './ResultSummary';

interface Props {
  module: Record<string, any>,
  assessment: Record<string, any>,
  showSummary?: boolean,
  showGoToElearning?: boolean,
  showModuleOverview?: boolean,
  showPrintButton?: boolean,
}

export default function ResultDetails({
  module,
  assessment,
  showSummary,
  showGoToElearning,
  showModuleOverview,
  showPrintButton,
}: Props) {
  const navigate = useNavigate();
  const moduleId = module.id;
  const { result } = assessment;
  const { t } = useTranslation('common');
  return (
    <>
      <div className="section">
        {(showSummary)
          ? (
            <ResultSummary
              summaryRiskRating={assessment.summaryRiskRating}
              moduleTitle={module.title}
            />
          )
          : null}
      </div>
      <div id="detailed-results" className="detailed-results">
        <h3>{t('result.details.title')}</h3>
        <ResultDetailsLegend />
        <RenderResults
          questions={result}
        />
        <div className="button-wrapper">
          {(showGoToElearning)
            ? <Button onClick={() => navigate(`/modules/${moduleId}#elearning`)}>{t('result.button.goToElearning')}</Button>
            : null}
          {(showModuleOverview)
            ? <Button onClick={() => navigate('/modules/')}>{t('buttons.moduleOverview')}</Button>
            : null}
          {(showPrintButton)
            ? <Button onClick={() => window.print()}>{t('buttons.print')}</Button>
            : null}
        </div>
      </div>
    </>
  );
}

ResultDetails.defaultProps = {
  showSummary: false,
  showGoToElearning: true,
  showModuleOverview: true,
  showPrintButton: true,
};
