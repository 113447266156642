import React from 'react';
import { useTranslation } from 'react-i18next';

export default function contentInnenreinigung() {
  const { t } = useTranslation('common');
  return ({
    playlist: [
      {
        id: 'elearning.video.sprayerCleaning.id',
        title: 'elearning.video.sprayerCleaning.text',
      },
    ],
    docs: [
      <a href={t('elearning.doc.befuellUndWaschplatzFuerSpritzgeraete.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.befuellUndWaschplatzFuerSpritzgeraete.text')}
      </a>,
      <a href={t('elearning.doc.spuelsystemeMitSeparatemSpuelkreislauf.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.spuelsystemeMitSeparatemSpuelkreislauf.text')}
      </a>,
      <a href={t('elearning.doc.interkantonaleEmpfehlungWaschplatz.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.interkantonaleEmpfehlungWaschplatz.text')}
      </a>,
    ],
  });
}
