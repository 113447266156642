import abdrift from './contentAbdrift';
import abschwemmung from './contentAbschwemmung';
import lagerung from './contentLagerung';
import applikationImFeld from './contentApplikationImFeld';
import aussenreinigung from './contentAussenreinigung';
import innenreinigung from './contentInnenreinigung';
import mischenUndBefuellen from './contentMischenUndBefuellen';
import waschplatz from './contentWaschplatz';
import transport from './contentTransport';

const elearningContent = {
  lagerung,
  'abschwemmung-und-erosion': abschwemmung,
  abdrift,
  'applikation-im-feld': applikationImFeld,
  aussenreinigung,
  innenreinigung,
  'mischen-und-befuellen': mischenUndBefuellen,
  'waschplatz-und-restmengen': waschplatz,
  'transport-zum-feld': transport,
};

export default elearningContent;
