/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import {
  Form, Button, Col, Row,
} from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import NotificationHandler from './notifications/NotificationHandler';
import LogoSection from './logos/LogoSection';
import TitleBar from './layout/PageTitle';

const encode = (data: Record<string, any>) => Object.keys(data)
  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
  .join('&');

export default function ContactForm() {
  const { t } = useTranslation('common');
  const [notification, SetNotification] = useState<Record<string, string>|null>(null);
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      organisation: '',
      reason: '',
      email: '',
      message: '',
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .required(t('form.validation.required', undefined)),
      lastName: Yup.string()
        .required(t('form.validation.required', undefined)),
      reason: Yup.string()
        .required(t('form.validation.required', undefined)),
      email: Yup.string()
        .email(t('form.validation.email.notValid', undefined))
        .required(t('form.validation.required', undefined)),
      message: Yup.string()
        .required(t('form.validation.required', undefined)),
    }),
    onSubmit: (values) => {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contact-form', ...values }),
      })
        .then(() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
          formik.resetForm();
          SetNotification({ variant: 'success', message: 'Vielen Dank für Ihre Nachricht. Wir werden uns bemühen, Ihr Anliegen schnellstmöglich zu beantworten.' });
        })
        .catch(() => {
          SetNotification({ variant: 'danger', message: 'Es ist etwas schief gelaufen. Bitte probieren Sie es später noch einmal.' });
        });
    },
  });

  return (
    <>
      <Row>
        <TitleBar
          pageTitle={t('form.labels.contact')}
        />
      </Row>
      <Row>
        <NotificationHandler variant={notification?.variant} message={notification?.message} />
      </Row>
      <Row>
        <Col>
          <section className="section">
            <Form name="contact-form" className="form contact-form" onSubmit={formik.handleSubmit}>
              <input type="hidden" name="form-name" value="contact-form" />
              <Form.Group className="mb-3" controlId="firstName">
                <Form.Label>{t('form.labels.firstName')}</Form.Label>
                <Form.Text className="required-field">{formik.touched.firstName}</Form.Text>
                <Form.Control
                  type="text"
                  placeholder={t('form.labels.firstName') ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="lastName">
                <Form.Label>{t('form.labels.lastName')}</Form.Label>
                <Form.Text className="required-field">{formik.touched.lastName}</Form.Text>
                <Form.Control
                  type="text"
                  placeholder={t('form.labels.lastName') ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                />
              </Form.Group>

              {/* Institution / Organisation */}
              <Form.Group className="mb-3" controlId="organisation">
                <Form.Label>{t('form.labels.organization')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('form.labels.organization') ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.organisation}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="email">
                <Form.Label>{t('form.labels.email')}</Form.Label>
                <Form.Text className="required-field">{formik.touched.email && formik.errors.email}</Form.Text>
                <Form.Control
                  type="email"
                  placeholder={t('form.labels.emailExample') ?? ''}
                  {...formik.getFieldProps('email')}
                  className={formik.errors.email ? 'invalid-field-value' : ''}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="reason">
                <Form.Label>{t('form.labels.reason')}</Form.Label>
                <Form.Select
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.reason}
                >
                  <option>{t('form.labels.pleaseSelect')}</option>
                  <option value={t('form.labels.reasonOptions.content') ?? ''}>{t('form.labels.reasonOptions.content')}</option>
                  <option value={t('form.labels.reasonOptions.support') ?? ''}>{t('form.labels.reasonOptions.support')}</option>
                  <option value={t('form.labels.reasonOptions.other') ?? ''}>{t('form.labels.reasonOptions.other')}</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="message">
                <Form.Label>{t('form.labels.message')}</Form.Label>
                <Form.Control
                  as="textarea"
                  style={{ height: '150px' }}
                  placeholder={t('form.labels.messageExample') ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                />
              </Form.Group>
              <div className="g-recaptcha" data-sitekey="6Ldk7pUiAAAAADs3RE4IKRF9mYejgD1yYjn5NX_i" />
              <div className="sct-form-button-nav">
                <Button variant="primary" type="submit">{t('buttons.send')}</Button>
              </div>
            </Form>
          </section>
        </Col>
      </Row>
      <Row>
        <Col>
          <section className="section">
            <LogoSection />
          </section>
        </Col>
      </Row>
    </>
  );
}
