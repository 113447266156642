import React from 'react';

interface Props {
    id: string,
    title?: string | null,
    className?: string
}

export default function YTVideo({ id, title, className }: Props) {
  return (
    <>
      <div>
        <h4>{title}</h4>
      </div>
      <div className={className}>
        <iframe
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${id}`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
        />
      </div>
    </>
  );
}

YTVideo.defaultProps = {
  title: null,
  className: 'sct-YT-video',
};
