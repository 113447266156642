/* eslint-disable import/no-import-module-exports */
import React, {
  useState, useEffect, useContext, Children,
} from 'react';
import { ReactReduxContext } from 'react-redux';
import { Button, Col, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import getCurrentUser from '../auth/getCurrentUser';
import { fetchQuestionsByTopic, submitAssessment, submitRetake } from './reducer/thunks';
import { setIsRetake } from './reducer/actions';
import { useAppSelector, useAppDispatch } from './reducer/reduxHooks';
import unsetQuestion from '../utils/unsetQuestion';
import SelectInput from './SelectInput';
import Checkbox from './CheckboxFn';
import OrderedAnswers from './OrderedAnswers';
import InputField from './InputField';
import questionMeetsCondition from '../utils/questionMeetsCondition';
import prepareCurrentAssessment from '../utils/prepareCurrentAssessment';
import IsLoading from './IsLoading';

type FormWrapperState = {
  step: number,
  submitSuccess: boolean
};

type FormWrapperProps = {
  moduleId: string,
};

export default function FormWrapper({
  moduleId,
}: FormWrapperProps) {
  const { i18n, t } = useTranslation('common');
  const dispatch = useAppDispatch();
  // checking if user has already an assessment
  const assessments = useAppSelector((state) => state.lessonState.lessons);
  const assessmentCompleted = assessments[moduleId]?.submitted || false;
  const questions = useAppSelector((state) => state.questionState.questions[moduleId]);
  const currentAssessment = useAppSelector(
    (state) => state.currentAssessmentState.currentAssessment[moduleId],
  );
  // State for user information
  const [user, setUser] = useState<Record<string, any>|null>(null);
  // next/submit button state
  const [buttonActive, setButtonActive] = useState<boolean>(true);
  // State for question pagination
  const [state, setState] = useState<FormWrapperState>({
    step: 0,
    submitSuccess: false,
  });
  const [prepared, setPrepared] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Accessing current Redux store
  const { store } = useContext(ReactReduxContext);

  if (questions === undefined) {
    dispatch<any>(fetchQuestionsByTopic(moduleId));
  }

  useEffect(() => {
    if (questions !== undefined) {
      if (!prepared) {
        prepareCurrentAssessment(dispatch, moduleId, questions);
        setPrepared(true);
      }
      setIsLoading(false);
    }
  }, [questions, currentAssessment]);

  useEffect(() => {
    const userData = getCurrentUser();
    setUser(userData);
  }, []);

  const next = () => {
    setState({ ...state, step: state.step + 1 });
  };

  const submit = async () => {
    // UPDATING STORE STATE
    // metadata to the assessment
    const formSubmitPayload = {
      uid: user?.uid,
      submitted: true,
      timestamp: new Date().getTime(),
    };
    // Set submitted true and add uid
    dispatch({ type: 'currentAssessment/assessmentFormSubmitted', payload: formSubmitPayload, moduleId });

    // Set isRetake to true
    const action = {
      type: '',
      payload: {
        isRetake: false,
        moduleId,
      },
    };
    dispatch(setIsRetake(action));

    // get updated Store state
    const storeState = store.getState().currentAssessmentState.currentAssessment[moduleId];

    if (assessmentCompleted === true) {
    // if user has an assessment, write data to retakes db-table

      // setup repetition data
      const payload = {
        key: moduleId,
        value: Array({ answers: storeState.assessment }),
      };

      dispatch<any>(submitRetake(payload));
    } else {
      const payload = {
        key: moduleId,
        value: storeState,
      };
      dispatch<any>(submitAssessment(payload));
    }
  };
  if (isLoading) {
    return <IsLoading />;
  }

  const childrenArray = Children.toArray([...questions]
    .sort((a: any, b: any) => a.order - b.order)
    .map((question: Record<string, any>) => {
      // setting question in current language
      const questionText = (i18n.language !== 'de')
        ? question[`question_${i18n.language}`] ?? question.question // fallback german
        : question.question;
      if (questionMeetsCondition(question, currentAssessment?.assessment) === true) {
        if (['checkbox', 'radio'].includes(question.type)) {
          return (
            <Checkbox
              type={question.type}
              question={questionText}
              order={question.order}
              subtitle={question.subtitle || null}
              moduleName={moduleId}
              currentAssessmentState={currentAssessment}
              questionId={question.id}
              key={question.id}
              imageSrc={(i18n.language !== 'de') ? question[`imageSrc_${i18n.language}`] : question.imageSrc}
              options={question.options}
              setButtonActive={setButtonActive}
            />
          );
        }
        if (question.type === 'select') {
          return (
            <SelectInput
              question={questionText}
              order={question.order}
              subtitle={question.subtitle || null}
              moduleName={moduleId}
              currentAssessmentState={currentAssessment}
              questionId={question.id}
              options={question.options}
              key={question.id}
              setButtonActive={setButtonActive}
            />
          );
        }
        if (question.type === 'answerRanking') {
          return (
            <OrderedAnswers
              question={questionText}
              order={question.order}
              options={question.options}
              subtitle={question.subtitle || null}
              moduleName={moduleId}
              currentAssessmentState={currentAssessment}
              questionId={question.id}
              key={question.id}
              setButtonActive={setButtonActive}
            >
              {question.options.map((option: any) => option.text).join('| ')}
            </OrderedAnswers>
          );
        }
        if (['computedQuestion', 'inputField'].includes(question.type)) {
          return (
            <InputField
              questionType={question.type}
              question={questionText}
              order={question.order}
              subtitle={question.subtitle || null}
              moduleName={moduleId}
              currentAssessmentState={currentAssessment}
              questionId={question.id}
              key={question.id}
              options={question.options}
              setButtonActive={setButtonActive}
            />
          );
        }
      }
      return null;
    }));

  const back = () => {
    // unset current state value(s)
    const { questionId, type } = Object(childrenArray[state.step]).props;
    unsetQuestion(store, dispatch, moduleId, questionId, type);
    setState({ ...state, step: state.step - 1 });
  };

  return (
    <Col>
      <section className="section">
        <div className="">
          <span>
            {Math.round((state.step / (childrenArray.length - 1)) * 100)}
            %
          </span>
        </div>
        <ProgressBar animated striped variant="success" now={(state.step / (childrenArray.length - 1)) * 100} />
      </section>
      <section className="section">
        <div>
          {childrenArray[state.step]}
        </div>
      </section>
      <section className="section">
        <div className="sct-form-button-nav">
          {(state.step !== 0)
            ? <Button variant="primary" title={t('buttons.title.back') ?? ''} onClick={back}>{t('buttons.back')}</Button>
            : null}
          {(state.step < childrenArray.length - 1)
            ? <Button variant="primary" disabled={buttonActive} title={(buttonActive) ? t('buttons.title.pleaseSelect') ?? '' : t('buttons.title.next') ?? ''} onClick={next}>{t('buttons.next')}</Button>
            : <Button variant="primary" disabled={buttonActive} title={(buttonActive) ? t('buttons.title.pleaseSelect') ?? '' : t('buttons.title.send') ?? ''} onClick={submit}>{t('buttons.send')}</Button>}
        </div>
      </section>
    </Col>
  );
}
