export default function isInArray(value: number | string | boolean | Array<any>, array: Array<any>) {
  const typeOfVar = typeof value;
  let match = false;
  if (['number', 'string', 'boolean'].includes(typeOfVar)) {
    return array.includes(value);
  }
  if (typeOfVar === 'object' && Array.isArray(value)) {
    value.forEach((v) => {
      if (array.includes(v)) {
        match = true;
      }
    });
  }
  return match;
}
