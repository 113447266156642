import React, { useEffect, useState } from 'react';
import { Button, ProgressBar, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import getCurrentUser from '../../auth/getCurrentUser';
import { dataService } from '../../services/DataService';
import IsLoading from '../IsLoading';
import CustomModal from '../CustomModal';
import RiskBar from '../results/RiskBar';
import ResultDetails from '../results/ResultDetails';
import RetakeOverviewTable from '../retakes/RetakesOverviewTable';
import getLocalDateStringFromTimestamp from '../../utils/getLocalDateStringFromTimestamp';

function AssessmentOverviewTable() {
  const [modules, setModules] = useState<Array<Record<string, any>> | any>();
  const [assessments, setAssessments] = useState<Array<Record<string, any>> | any>(null);
  const [retakes, setRetakes] = useState<Array<Record<string, any>> | any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  useEffect(() => {
    const user = getCurrentUser();
    async function getData() {
      const modulesRes = await dataService.getItems('modules');
      const assessmentsRes = await dataService.process('assessments', user?.uid);
      const retakesRes = await dataService.process('retakes', user?.uid);

      if (modulesRes.statusCode === 200 && assessmentsRes.statusCode === 200) {
        setModules(modulesRes.body);
        setAssessments(assessmentsRes.body);

        if (retakesRes.statusCode === 200) {
          setRetakes(retakesRes.body);
        } else {
          setRetakes(null);
        }
      }
      setIsLoading(false);
    }
    getData();
  }, []);

  if (isLoading) {
    return <IsLoading />;
  }
  if (Array.isArray(assessments) === true) {
    return (
      <Table responsive striped className="assessment-overview-table">
        <caption>{t('pages.assessmentOverview.table.caption')}</caption>
        <colgroup>
          <col className="module-col" />
          <col className="riskbar-col" />
          <col className="button-col" />
          <col className="button-col" />
        </colgroup>
        <thead>
          <tr>
            <th>{t('pages.assessmentOverview.table.column.item1')}</th>
            <th>{t('pages.assessmentOverview.table.column.item2')}</th>
            <th> </th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {modules
            .filter((module: Record<string, any>) => module.domain !== 'test')
            .sort((a: { order: number; }, b: { order: number; }) => a.order - b.order)
            .reverse()
            .sort(
              (a: Record<string, string>, b: Record<string, string>) => {
                const domainA = a.domain.toUpperCase();
                const domainB = b.domain.toUpperCase();
                if (domainA < domainB) {
                  return -1;
                }
                if (domainA > domainB) {
                  return 1;
                }
                return 0;
              },
            )
            .reverse()
            .map((module: Record<string, string>) => {
              const [assessment] = assessments.filter(
                (assessmentData: Record<string, any>) => assessmentData.id === module.id,
              );
              // set moduleName to selected language
              const moduleName = (i18next.language !== 'de') ? module[`title_${i18next.language}`] ?? module.title : module.title;
              let moduleRetakes;
              if (retakes) {
                moduleRetakes = retakes.filter(
                  (retake: Record<string, any>) => retake[module.id],
                );
              }
              return (
                <tr key={moduleName}>
                  <td>
                    <Link to={`/modules/${module?.id}`}>{moduleName}</Link>
                  </td>
                  <td>
                    {(assessment)
                      ? <RiskBar summaryRiskRating={assessment?.summaryRiskRating} />
                      : <ProgressBar now={0} />}
                  </td>
                  <td className="button-col">
                    {(assessment)
                      ? (
                        <CustomModal
                          modalTitel={`${t('pages.assessmentOverview.modal.title')} ${moduleName}: Assessment, ${getLocalDateStringFromTimestamp(assessment?.timestamp)}`}
                          buttonText={t('pages.assessmentOverview.table.button.details')}
                          showPrintButton
                        >
                          <ResultDetails module={module} assessment={assessment} showSummary />
                        </CustomModal>
                      )
                      : <Button variant="primary" disabled title={t('pages.assessmentOverview.table.button.disabled') ?? ''}>{t('pages.assessmentOverview.table.button.details')}</Button>}
                  </td>
                  <td className="button-col">
                    {(retakes && moduleRetakes.length >= 1)
                      ? (
                        <CustomModal
                          fullscreen
                          modalTitel={`${t('pages.assessmentOverview.modal.title')} ${moduleName}`}
                          buttonText={t('pages.assessmentOverview.table.button.retake')}
                        >
                          <RetakeOverviewTable module={module} retakes={moduleRetakes} />
                        </CustomModal>
                      )
                      : <Button variant="primary" disabled title={t('pages.assessmentOverview.table.button.disabled') ?? ''}>{t('pages.assessmentOverview.table.button.retake')}</Button>}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  }
  return (
    <div>
      {t('pages.assessmentOverview.text.noResults')}
      &nbsp;
      <Link to="/modules">{t('pages.assessmentOverview.text.goToModules')}</Link>
    </div>
  );
}

export default AssessmentOverviewTable;
