import { getAuth } from 'firebase/auth';
// import { initializeApp } from 'firebase/app';
// import { firebaseConfig } from '../firebase/firebase-config';

export default async function getJWT(): Promise<string | null> {
  // const app = initializeApp(firebaseConfig);
  // const auth = getAuth(app);

  /**
   * Bug:
   * getAuth() provokes 502 error "Dynamic require of 'fs' not supported" on Netlify
   * Don't know yet where this comes from exactly
   *
   * Workaround with try-catch
   */

  try {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      return user.getIdToken(/* forceRefresh */ true).then((idToken) => idToken)
        .catch((error) => error);
    }
  } catch (error) {
    // just don't screw up
  }
  return null;
}
