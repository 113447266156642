import { dataService } from '../services/DataService';

interface setUserProps {
    userCredential: Record<string, any>,
    userGroup: string,
    name: string,
    lastName: string
}

export default async function setUser({
  userCredential,
  userGroup,
  name,
  lastName,
}: setUserProps) {
  // User metadata to set
  const userData = {
    name,
    lastName,
    email: userCredential.user.email,
    userGroup,
    uid: userCredential.user.uid,
    roles: ['subscriber'],
    creationData: new Date().getTime(),
  };

  const res = await dataService.setItem('users', userCredential.user.uid, userData);
  return {
    statusCode: res.statusCode,
    body: res.body,
  };
}
