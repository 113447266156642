import { PayloadAction, AnyAction } from '@reduxjs/toolkit';

export function setInitialLessonState(moduleId: string) {
  return {
    type: 'lessons/initialLessonStateSet',
    payload: {
      moduleId,
      assessment: {},
      submitted: false,
      timestamp: null,
      uid: null,
    },
  };
}

export function assessmentsLoaded(payload: PayloadAction<Record<string, any>>) {
  return {
    type: 'lessons/assessmentsLoaded',
    payload,
  };
}

export function retakesLoaded(payload: PayloadAction<Record<string, any>>) {
  return {
    type: 'lessons/retakesLoaded',
    payload,
  };
}

export function modulesLoaded(payload: any) {
  return {
    type: 'modules/modulesLoaded',
    payload,
  };
}

export function questionsLoaded(payload: PayloadAction<Record<string, any>>) {
  return {
    type: 'questions/questionsLoaded',
    payload,
  };
}

export function retakesEnabled(action: PayloadAction<Record<string, any>>) {
  return {
    type: 'lessons/enableRetakes',
    payload: action.payload,
  };
}

export function setIsRetake(action: PayloadAction<Record<string, any>>) {
  return {
    type: 'lessons/setIsRetake',
    payload: action.payload,
  };
}

export function assessmentPrepared(payload: any) {
  return {
    type: 'currentAssessment/questionsPreloaded',
    payload,
  };
}
