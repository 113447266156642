import React from 'react';
import { Col, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useAppDispatch } from '../reducer/reduxHooks';
import { setIsRetake } from '../reducer/actions';
import Results from '../results/Results';
import CustomModal from '../CustomModal';

interface Props {
  module: Record<string, any>,
  imageSrc: string,
  assessmentSubmitted: boolean
}

export default function SelbstcheckButtonBox({
  module, imageSrc, assessmentSubmitted,
}: Props) {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // set moduleName to selected language
  const moduleName = (i18next.language !== 'de') ? module[`title_${i18next.language}`] ?? module.title : module.title;

  function repeat() {
    // metadata to the assessment
    const action = {
      type: '',
      payload: {
        isRetake: true,
        moduleId: module.id,
      },
    };
    // Set redux store state submitted to false
    dispatch(setIsRetake(action));
    // Redirect to selbstcheck questions
    navigate((module) ? `/modules/${module.id}/selbstcheck` : '');
  }

  return (
    <Col>
      <section className="section">
        <Card className="sct-bs-card-row">
          <Card.Img className="sct-bs-card-img-top" variant="top" src={imageSrc} />
          <Card.Body>
            <Card.Title>{t('assessment.title')}</Card.Title>
            {(assessmentSubmitted)
              ? (
                <>
                  <Card.Text>
                    <Trans i18nKey="assessment.card.text.submitted" ns="common">
                      Sie haben den Selbstcheck im Bereich&nbsp;
                      {{ module: moduleName }}
                      absolviert.
                    </Trans>
                  </Card.Text>
                  <div className="button-wrapper">
                    <CustomModal
                      buttonText={t('assessment.card.button.showResult')}
                    >
                      <Results
                        collection="assessments"
                        module={module}
                        showGoToElearning={false}
                      />
                    </CustomModal>
                    <Button variant="primary" onClick={() => repeat()}>{t('assessment.card.button.repeatSelfCheck')}</Button>
                  </div>
                </>
              )
              : (
                <>
                  <Trans i18nKey="assessment.card.text.notSubmitted" ns="common">
                    <Card.Text>
                      Machen Sie den Selbstcheck und testen Sie Ihre Praxis im Bereich&nbsp;
                      {{ module: moduleName }}
                      .
                    </Card.Text>
                    <ul>
                      <li>Der erste Durchgang zählt.</li>
                      <li>Nehmen Sie sich genügend Zeit.</li>
                      <li>Wählen Sie die Antworten, die Ihrer Meinung nach am besten zur Situation auf Ihrem Hof passt.</li>
                      <li>Sie können den Selbstcheck jederzeit wiederholen. Dies wird in der Bewertung jedoch nicht berücksichtigt. Die Ergebnisse können unter der Rubrik «Meine Ergebnisse» eingesehen werden.</li>
                    </ul>
                  </Trans>
                  <Button variant="primary" onClick={() => repeat()}>{t('assessment.card.button.startSelfCheck')}</Button>
                </>
              )}
          </Card.Body>
        </Card>
      </section>
    </Col>
  );
}
