import React, { ReactFragment } from 'react';
import { Alert } from 'react-bootstrap';

interface Props {
    variant: string | undefined,
    message: string | ReactFragment | undefined
}

export default function NotificationHandler({ variant, message }: Props) {
  if (message ?? variant) {
    return (
      <Alert variant={variant}>
        {message}
      </Alert>
    );
  }
  return null;
}
