import React from 'react';
import { useTranslation } from 'react-i18next';

export default function contentTransport() {
  const { t } = useTranslation('common');
  return ({
    playlist: [
      {
        id: 'elearning.video.transportToTheField.id',
        title: 'elearning.video.transportToTheField.text',
      },
    ],
    docs: [
      <a href={t('elearning.doc.verordnungBefoerderungGefaehrlicherGueter.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.verordnungBefoerderungGefaehrlicherGueter.text')}
      </a>,
      <a href={t('elearning.doc.versandUndTransportGefaehrlicherGueterAG.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.versandUndTransportGefaehrlicherGueterAG.text')}
      </a>,
      <a href={t('elearning.doc.toppsHandbuchGuteFachlichePraxisBessererGewaesserschutz.link') ?? ''} target="_blank" rel="noreferrer">
        {t('elearning.doc.toppsHandbuchGuteFachlichePraxisBessererGewaesserschutz.text')}
      </a>,
    ],
  });
}
