/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Button, ProgressBar, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import RiskBar from '../results/RiskBar';
import CustomModal from '../CustomModal';
import getLocalDateStringFromTimestamp from '../../utils/getLocalDateStringFromTimestamp';
import ResultDetails from '../results/ResultDetails';

interface Props {
    module: Record<string, any>,
    retakes: Array<Record<string, any>> | any
}

export default function RetakeOverviewTable({
  module, retakes,
}: Props) {
  const { t } = useTranslation('common');
  return (
    <Table responsive striped className="retakes-overview-table">
      <caption>{t('pages.retakeOverview.table.caption')}</caption>
      <colgroup>
        <col className="date-col" />
        <col className="riskbar-col" />
        <col className="button-col" />
      </colgroup>
      <thead>
        <tr>
          <th>{t('pages.retakeOverview.table.column.item1')}</th>
          <th>{t('pages.retakeOverview.table.column.item2')}</th>
          <th>{t('pages.retakeOverview.table.column.item3')}</th>
        </tr>
      </thead>
      <tbody>
        {retakes[0][module.id]
          .sort((a: { timestamp: number; }, b: { timestamp: number; }) => b.timestamp - a.timestamp)
          .map((retake: Record<string, any>, idx: number) => (
            <tr key={`row-${idx}`}>
              <td>
                {getLocalDateStringFromTimestamp(retake.timestamp)}
              </td>
              <td>
                {(retake)
                  ? <RiskBar summaryRiskRating={retake?.summaryRiskRating} />
                  : <ProgressBar now={0} />}
              </td>
              <td>
                {(retake)
                  ? (
                    <CustomModal
                      modalTitel={t(`Modul ${module.title}: Wiederholung vom ${getLocalDateStringFromTimestamp(retake.timestamp)}`) ?? undefined}
                      buttonText={t('buttons.details')}
                    >
                      <ResultDetails module={module} assessment={retake} showSummary />
                    </CustomModal>
                  )
                  : <Button variant="primary" disabled>{t('buttons.details')}</Button>}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}
