/* eslint-disable no-param-reassign */
/**
 * lessonReducer.tsx
 *
 *
 */
import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

// Define a type for the slice state
interface assessmentState {
  lessons: Record<string, any>,
}

// Define the initial state using that type
const initialState: assessmentState = {
  lessons: {},
};

export const lessonsSlice = createSlice({
  name: 'lessons',
  initialState,
  reducers: {
    assessmentsLoaded: (state, action: PayloadAction<any>) => {
      state.lessons = action.payload;
    },
    // retakesLoaded: (state, action: PayloadAction<any>) => {
    //   state.lessons[action.payload.moduleName] = action.payload;
    // },
    initialLessonStateSet: (state, action: PayloadAction<any>) => {
      state.lessons[action.payload.moduleId] = action.payload;
    },
    ASSESSMENT_FORM_SUBMITTED: (state, action: PayloadAction<any>) => {
      state.lessons[action.payload.moduleName].submitted = action.payload.submitted;
      state.lessons[action.payload.moduleName].uid = action.payload.uid;
      state.lessons[action.payload.moduleName].timestamp = action.payload.timestamp;
    },
    enableRetakes: (state, action: PayloadAction<any>) => {
      state.lessons[action.payload.moduleId].retakesEnabled = action.payload.retakesEnabled; // boolean
    },
    setIsRetake: (state, action: PayloadAction<any>) => {
      state.lessons[action.payload.moduleId].isRetake = action.payload.isRetake; // boolean
    },
  },
});

export const {
  ASSESSMENT_FORM_SUBMITTED,
  assessmentsLoaded,
  initialLessonStateSet,
  enableRetakes,
} = lessonsSlice.actions;

export default lessonsSlice.reducer;
