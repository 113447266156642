import React from 'react';

export default function PrivacyPolicy() {
  return (
    <>
      <section className="module-title-section">
        <h1>Nutzungsbedingungen & Datenschutz</h1>
      </section>
      <section className="section">
        <h2>Rechtliche Hinweise und rechtliche Nutzungsbedingungen</h2>
        <p>
          Die Web-App Selbstchecktool ist ein Produkt der AGRIDEA.
          Beim Aufrufen der Web-App Selbstchecktool und der mit ihr verlinkten weiteren Webseiten
          akzeptieren Sie unsere Nutzungsbedingungen und unsere folgenden rechtlichen Hinweise.
        </p>
        <h2>Haftung in Bezug auf die Inhalte und Verknüpfungen</h2>
        <p>
          Die bearbeiteten Beiträge und Angaben sind nur im Augenblick ihrer
          Veröffentlichung aktuell. In der Folge werden sie durch Aktualisierungen
          regelmässig ergänzt. Die AGRIDEA schliesst jede Haftung aus hinsichtlich
          der technischen Verfügbarkeit und Betriebssicherheit sowie der Genauigkeit
          und Vollständigkeit des Inhalts der Web-App. Wenn eine Nutzerin oder ein
          Nutzer aufgrund von Daten, die sie oder er ab der Web-App Selbstchecktool
          entnahm, Handlungen vornehmen könnte, die zu einem Schaden führen, kann die
          AGRIDEA dafür nicht haftbar gemacht werden. Die Angaben und anderen Dienstleistungen
          von Dritten – sowohl auf den durch die AGRIDEA betriebenen Internetportalen wie die
          auf fremden Webseiten – sind in der ausschliesslichen Verantwortung dieser Dritten.
          Die AGRIDEA haftet auch nicht für den Inhalt oder die korrekte Funktionsfähigkeit
          von Websites Dritter, die sie über Verknüpfungen zugänglich macht. Die angebotenen
          Angaben und Dienstleistungen können jederzeit ohne Vorankündigung geändert, ersetzt
          oder gelöscht werden. Dieses Recht gilt auch für den vorliegenden Haftungsausschluss.
        </p>
        <h2>Urheberrecht</h2>
        <p>
          Der Inhalt und der Aufbau der Web-App Selbstchecktool sind urheberrechtlich geschützt.
          Nutzerinnen und Nutzer müssen in folgenden Fällen jeweils im Voraus das Einverständnis
          der AGRIDEA einholen: Wenn sie die Inhalte der Web-App Selbstchecktool ganz oder teilweise
          wiedergeben oder auf elektronischem Weg bzw. auf eine andere Art und Weise verbreiten
          wollen. Wenn sie die Änderung oder die Aktivierung von Verknüpfungen vornehmen wollen.
          Wenn sie Inhalte der Web-App Selbstchecktool für geschäftliche oder öffentliche Zwecke
          einsetzen wollen und/oder einen einkommengenerierenden Gebrauch der Webseite der AGRIDEA
          beabsichtigen. Dies gilt insbesondere für das Vorhaben, auf der Webseite von Dritten einen
          Link zur Web-App Selbstchecktool einzugeben. Nutzerinnen und Nutzer benötigen keine
          Erlaubnis seitens der AGRIDEA, wenn sie die Inhalte der Web-App Selbstchecktool für
          persönliche und private Zwecke nutzen, drucken, down-loaden. Ohne ausdrückliche
          Genehmigung seitens der AGRIDEA sind die Wiedergabe und/oder die Verbreitung des Inhalts
          ihrer Webseiten ein Verstoss gegen das «Bundesgesetz über das Urheberrecht und verwandte
          Schutzrechte» und können zu sowohl zivilen wie strafrechtlichen Konsequenzen führen.
        </p>
        <h2>Datenschutz</h2>
        <p>
          Gestützt auf Artikel 13 der «Schweizerischen Bundesverfassung» und auf die diversen
          datenschutzrechtlichen Bestimmungen zum Schutz von Personendaten hat jede Person Anspruch
          auf Schutz ihrer Privatsphäre sowie auf Schutz vor Missbrauch ihrer persönlichen Daten.
          Die AGRIDEA hält diese Bestimmungen ein. Es werden nur die Daten gespeichert, die Sie beim
          Ausfüllen der Formulare auf der Website eingeben. Ihre persönlichen Daten werden bei der
          AGRIDEA auf angemessene Art verwendet und für einen klar definierten Zweck genutzt. Ohne
          Ihr ausdrückliches Einverständnis werden diese Daten einer Dritten oder einem Dritten
          nicht weitergeleitet. Die AGRIDEA macht im Weiteren die Nutzerinnen und Nutzer auf den
          Umstand aufmerksam, dass die Kommunikation auf elektronischem Weg nicht als sicher
          und/oder vertraulich betrachtet werden kann.
        </p>
        <h3>Speicherung der Daten</h3>
        <p>
          Die Web-App Selbstchecktool verwendet für die Verwaltung der Benutzerkonten und
          Nutzerdaten einen Service von Google. Die Daten werden dabei auf einem Server
          in der EU gespeichert.
        </p>
        <h3>Cookies</h3>
        <p>
          Die Web-App Selbstchecktool verwendet einerseits Cookies zur Verbesserung des
          Nutzungserlebnisses. Diese Cookies dienen beispielsweise der Wiedererkennung der
          Nutzer/innen und Ihrer Spracheinstellungen.
        </p>
        <p>
          Andererseits werden Cookies zur Messung der Nutzung der Web-App verwendet. Zur Messung
          nutzt die Web-App den externen Service Google Analytics.
        </p>
        <h2>Missbräuchliche Verwendung</h2>
        <p>
          Die AGRIDEA behält sich die Einleitung von rechtlichen Schritten vor, falls ihre
          Webseite missbräuchlich oder gesetzeswidrig verwendet wird und/oder bei ihr
          und/oder bei Dritten technische Betriebsstörungen verursachen.
        </p>
        <p>
          Lindau, Lausanne, Cadenazzo, Januar 2023.
        </p>
      </section>
    </>
  );
}
